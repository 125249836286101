import { UnreadMentionsIndicator, UnreadMessageCounter } from '@verbit-ai/chat-frontend'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 60px);
    background-color: ${palette('white', 0)};
`

export const BodyContainer = styled(Container)`
    position: relative;
    display: flex;
`

export const TaskBarContainer = styled.div<{ taskType?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${ifProp({ taskType: 'onboarding' }, '1px', '60px')};
    background-color: transparent;
`

export const TaskBarContent = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${palette('grey', 13)};
`

export const TaskBarTitle = styled.div`
    font-size: 16px;
    color: ${palette('navy', 6)};
`

export const TaskBarButtons = styled.div<{
    $isRealTimeReadOnly?: boolean
    $eventsMarkingFlag?: boolean
}>`
    display: ${ifProp(
        ({ $isRealTimeReadOnly, $eventsMarkingFlag }) => $isRealTimeReadOnly && !$eventsMarkingFlag,
        'none',
        'flex',
    )};
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 22%;
    min-width: 290px;
    max-width: 422px;
    border-left: 1px solid ${palette('grey', 13)};
    border-bottom: 1px solid ${palette('grey', 13)};
`

export const TaskBarButton = styled.div<{
    isActive?: boolean
    highlightBackground?: boolean
    isVisible: boolean
}>`
    position: relative;
    display: ${ifProp('isVisible', 'flex', 'none')};
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70px;
    color: ${palette('navy', 2)};
    transition: stroke-width 0.3s ease;

    > svg {
        width: 22px;
        height: 22px;
        stroke-width: 1px !important ; // just an override solution to make sure all icons from icons-lib are the same size
    }

    &:hover {
        color: ${palette('blue', 0)};
    }
    ${ifProp(
        'isActive',
        css`
            color: ${palette('blue', 0)};
        `,
    )}
    ${ifProp(
        'highlightBackground',
        css`
            &:hover {
                background-color: transparent;
                animation: scale-animation 0.9s ease 0.2s;
                animation-fill-mode: forwards;
            }
            ${ifProp(
                'isActive',
                css`
                    border-bottom: 3px solid transparent;
                    border-image: linear-gradient(
                            90deg,
                            ${palette('blue', 4)},
                            ${palette('blue', 2)}
                        )
                        1;
                    border-image-slice: 1;
                    animation: scale-indicator-animation 0.2s ease-out 0.3s;
                    transition: 0.2s ease-in-out;

                    > svg {
                        stroke: ${palette('blue', 0)};
                    }
                `,
            )}
        `,
    )}
`

export const StyledUnreadMessageCounter = styled(UnreadMessageCounter)`
    position: absolute;
    top: -6px;
    right: -9px;
`

export const StyledUnreadMentionsIndicator = styled(UnreadMentionsIndicator)`
    position: absolute;
    top: -8px;
    right: calc(100% - 1px);
`

export const ChatIcon = styled.div`
    position: relative;
    display: flex;
`
