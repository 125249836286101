import { Transforms, Editor, Text } from 'slate'
import { useSlateStatic } from 'slate-react'

import { useContextMenuBuilder } from 'src/components/ContextMenu/ContextMenuBuilder'
import * as Icons from 'src/components/icons'
import { useSpellchecker } from 'src/components/Spellchecker/Spellchecker'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'

import { Spellchecker } from '.'

export function useSpellingContextMenu() {
    const { spellchecker } = useSpellchecker()
    const editor = useSlateStatic()
    const isRealTimeReadOnly = useIsReadOnlyMode()

    useContextMenuBuilder(
        'text',
        (menu, [node], range) => {
            if (isRealTimeReadOnly) return

            const normalizedRange = { ...range }

            // if the range ends at the beginning of a new node, exclude it
            if (range.focus.offset === 0) {
                const normalizedFocus = Editor.before(editor, range.focus, {
                    unit: 'offset',
                    distance: 1,
                })

                if (normalizedFocus) {
                    normalizedRange.focus = normalizedFocus
                }
            }
            let totalTextNodes = Array.from(
                Editor.nodes(editor, { at: normalizedRange, match: Text.isText }),
            )

            if (
                totalTextNodes.length === 1 &&
                Text.isText(node) &&
                Spellchecker.isSpellingError(node)
            ) {
                const suggestions = spellchecker.suggest(Editor.string(editor, range)).slice(0, 5)

                for (const suggestion of suggestions) {
                    menu.addItem(
                        {
                            label: suggestion,
                            icon: <Icons.SpellingErrorIcon />,
                            onAction: (editor, range) => {
                                if (range.current) {
                                    Transforms.insertText(editor, suggestion, { at: range.current })
                                }
                            },
                        },
                        'spelling',
                    )
                }
            }
        },
        [editor],
    )
}
