import { useCallback, useEffect, useMemo, useRef, useState, ChangeEvent } from 'react'
import { UserIcon, LocationIcon, ProfessionalTermIcon, TrashIcon } from '@verbit-ai/icons-library'
import { Icon, Intent } from '@blueprintjs/core'
import { flow, filter, some, sortBy, map } from 'lodash/fp'
import Fuse from 'fuse.js'

import { useAnalytics } from 'src/analytics'
import { GlossaryEventSource } from 'src/analytics/client'
import { GlossaryTerm, GlossaryTermCategory } from 'src/models'
import { useGlossary } from 'src/state/GlossaryProvider'
import { useModal } from 'src/state/ModalProvider'
import useFuse from 'src/hooks/useFuse'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { useToast } from 'src/components/Toasts/ToastContext'
import { UUID } from 'src/utils/uuid'
import { trimText } from 'src/utils/string'
import { useSession } from 'src/state/session'

import * as Styled from './styled'
import { RowProps } from './Row'
import { useNetworkStatus } from 'src/hooks/useNetworkStatus'

export function validateTermText(text: string) {
    // empty or whitespace-only terms are not allowed
    return !/^\s*$/.test(text)
}

export const TEMPORARY_GLOSSARY_ID = 'temporary'
export const TAG_MAX_CHARS_LENGTH = 50

export type ActiveItemSource = 'mouse' | 'keyboard'

export const glossaryCategories: GlossaryTermCategory[] = ['person', 'place', 'term']

export const glossaryCategoryLabels: Record<GlossaryTermCategory, string> = {
    term: 'Professional Term',
    person: 'Person',
    place: 'Place',
}

export const categoryIcons: { [key in GlossaryTermCategory]: JSX.Element } = {
    person: <UserIcon />,
    place: <LocationIcon />,
    term: <ProfessionalTermIcon />,
}

export interface UseGlossaryListProps {
    source: GlossaryEventSource
    initialSearchTerm?: string
    onTermSelected?: (term: GlossaryTerm, promise?: Promise<UUID | null>) => void
    onClose?: () => void
    withExpandableItems?: boolean
    withShortcuts?: boolean
    withFlatAddItemOptions?: boolean
    submenuSide?: 'right' | 'left'
    itemFilter?: (item: GlossaryTerm) => boolean
    itemSorter?: (item: GlossaryTerm) => string | number
}

export const useGlossaryList = ({
    source,
    initialSearchTerm = '',
    onTermSelected,
    onClose,
    withExpandableItems,
    withShortcuts,
    withFlatAddItemOptions,
    submenuSide,
    itemFilter = () => true,
    itemSorter = (term) => term.text.toLowerCase(),
}: UseGlossaryListProps) => {
    const analytics = useAnalytics()
    const { glossary, addTerm, deleteTerm } = useGlossary()
    const { taskId } = useSession()
    const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm)
    const [activeItemIdx, setActiveItemIdx] = useState(0)
    const [isAddItemExpanded, setIsAddItemExpanded] = useState(false)
    const [expandedItemIdx, setExpandedItemIdx] = useState(-1)
    const [activeItemSource, setActiveItemSource] = useState<ActiveItemSource>('mouse')
    const itemRefs = useRef<Array<HTMLElement | null>>([])
    const searchInputRef = useRef<HTMLInputElement>(null)
    const isTermValidForNewGlossary = useMemo(() => validateTermText(searchTerm), [searchTerm])
    const { openModal, closeModal, currentModal } = useModal()
    const addToast = useToast()
    const isOnline = useNetworkStatus()

    const fuseResults = useFuse(glossary.terms, trimText(searchTerm), {
        keys: ['text'],
        includeScore: true,
        ignoreLocation: true,
    })
    const filteredItems = useMemo(
        () =>
            searchTerm.length > 0 || fuseResults.length > 0
                ? flow(
                      filter(
                          (r: Fuse.FuseResult<GlossaryTerm>) =>
                              itemFilter(r.item) && !!r.score && r.score < 0.15,
                      ),
                      map('item'),
                      sortBy(itemSorter),
                  )(fuseResults)
                : flow(filter(itemFilter), sortBy(itemSorter))(glossary.terms),
        [fuseResults, glossary.terms, itemFilter, itemSorter, searchTerm.length],
    )

    const isAddItemActive = !withFlatAddItemOptions && activeItemIdx === filteredItems.length
    const anySubmenuExpanded = expandedItemIdx !== -1 || isAddItemExpanded
    const isGlossaryModalOpen = currentModal === 'glossaryDelete' || currentModal === 'glossaryEdit'

    const handleSearchInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
        setActiveItemIdx(0)
        setExpandedItemIdx(-1)
    }, [])

    const handleTermAddRequest = useCallback(
        (text: string, category: GlossaryTermCategory) => {
            const trimmedTerm = trimText(text)
            // set temporary id as an indication, that we need to replace it with the real id later
            let newTerm = {
                id: TEMPORARY_GLOSSARY_ID,
                text: trimmedTerm,
                category,
            }

            if (trimmedTerm.length > TAG_MAX_CHARS_LENGTH) {
                addToast({
                    intent: Intent.NONE,
                    icon: <Icon icon="issue" color="#c23030" />,
                    message: (
                        <span>
                            <strong>{trimmedTerm.slice(0, 30)}...</strong> could not be added,
                            glossary term can hold up to 50 characters.
                        </span>
                    ),
                })
                return
            }

            const promise = addTerm(newTerm, source)
            onTermSelected?.(newTerm, promise)
            setSearchTerm('')
            addToast({
                intent: Intent.NONE,
                icon: <Icon icon="tick" color="#0f9960" />,
                message: (
                    <span>
                        <strong>{trimmedTerm}</strong> has been added.
                    </span>
                ),
            })
        },
        [addTerm, addToast, onTermSelected, source],
    )

    const onEdit = useCallback(
        (editedTerm: GlossaryTerm) => {
            editedTerm.text = trimText(editedTerm.text)
            onTermSelected?.(editedTerm)
            closeModal()
            searchInputRef.current?.focus()
        },
        [onTermSelected, closeModal],
    )

    const onDelete = useCallback(
        (term: GlossaryTerm) => {
            setExpandedItemIdx(-1)
            deleteTerm(term, source)
            closeModal()
            onClose?.()
            addToast({
                intent: Intent.NONE,
                icon: (
                    <TrashIcon size={16} color="#c23030" style={{ margin: 12, marginRight: 0 }} />
                ),
                message: (
                    <span>
                        <strong>{term.text}</strong> has been deleted.
                    </span>
                ),
            })
        },
        [deleteTerm, source, closeModal, onClose, addToast],
    )

    const handleTermEditRequest = useCallback(
        (term: GlossaryTerm) => {
            openModal({
                name: 'glossaryEdit',
                props: {
                    source,
                    term,
                    onEdit,
                    onClose: () => {
                        searchInputRef.current?.focus()
                    },
                },
            })
        },
        [onEdit, openModal, source],
    )

    const handleTermDeleteRequest = useCallback(
        (term: GlossaryTerm) => {
            openModal({
                name: 'glossaryDelete',
                props: {
                    term,
                    onDelete,
                    onClose: () => {
                        searchInputRef.current?.focus()
                    },
                },
            })
        },
        [onDelete, openModal],
    )

    // big problem: when the mouse is not moved and the list is scrolled via the arrow keys, once the mouse enters
    // a new list item, mouse over activates non-the-less. Therefore we only expand the submenu, when the mouse
    // already is the active item source. Otherwise we just set the active item source to be the mouse in case the
    // mouse actually moves and this function runs the next time. If the mouse doesn't move, the arrow keys will override
    // the active item source again.
    const handleItemMouseOver = useCallback(
        (index: number) => {
            if (activeItemSource === 'mouse') {
                setActiveItemIdx(index)

                if (withExpandableItems) {
                    setExpandedItemIdx(index)
                }
            }
            setActiveItemSource('mouse')
        },
        [activeItemSource, withExpandableItems],
    )

    const handleGlossaryItemMouseOver = useCallback(
        (term: GlossaryTerm, index: number) => {
            handleItemMouseOver(index)
        },
        [handleItemMouseOver],
    )

    const handleItemClick = useCallback(
        (term: GlossaryTerm) => {
            onTermSelected?.(term)
            analytics?.sendUseGlossaryTerm(
                taskId ?? '',
                term.id,
                term.category,
                term.text,
                term.verified ?? false,
                source,
                initialSearchTerm,
            )
        },
        [analytics, initialSearchTerm, onTermSelected, source, taskId],
    )

    const handleAddItemMouseOver = useCallback(() => {
        if (withFlatAddItemOptions) {
            return
        }

        setActiveItemIdx(filteredItems.length)
        setExpandedItemIdx(-1)
        setIsAddItemExpanded(true)
        setActiveItemSource('mouse')
    }, [filteredItems.length, withFlatAddItemOptions])

    const addItemOptions = useMemo<RowProps[]>(() => {
        const trimmedGlossaryList = glossary.terms.map((item) => ({
            ...item,
            text: item.text.trim(),
        }))

        return glossaryCategories.map((category, addOptionIndex) => {
            const idx = filteredItems.length + addOptionIndex
            const disabled = some({ category, text: searchTerm.trim() }, trimmedGlossaryList)

            return {
                text: glossaryCategoryLabels[category],
                icon: categoryIcons[category],
                onClick: () => handleTermAddRequest(searchTerm, category),
                onMouseOver: withFlatAddItemOptions ? () => handleItemMouseOver(idx) : undefined,
                disabled,
                active:
                    withFlatAddItemOptions && activeItemIdx >= filteredItems.length
                        ? activeItemIdx === idx
                        : undefined,
                visibleOnRowHoverChildren: disabled ? (
                    <Styled.DisabledLabel>Already in Glossary</Styled.DisabledLabel>
                ) : (
                    <Styled.ItemActionButton className="visible-on-row-hover">
                        Add
                    </Styled.ItemActionButton>
                ),
            }
        })
    }, [
        activeItemIdx,
        filteredItems.length,
        glossary.terms,
        handleItemMouseOver,
        handleTermAddRequest,
        searchTerm,
        withFlatAddItemOptions,
    ])

    const displayAddItem = useMemo(
        () =>
            isOnline &&
            isTermValidForNewGlossary &&
            addItemOptions.some(({ disabled }) => !disabled),
        [addItemOptions, isTermValidForNewGlossary],
    )

    const getNextActiveItemIdx = useCallback(
        (prevIdx: number, direction: 'up' | 'down') => {
            let lastIdx = filteredItems.length - 1

            if (isTermValidForNewGlossary) {
                lastIdx += withFlatAddItemOptions ? addItemOptions.length : 1
            }

            if (direction === 'down') {
                return prevIdx === lastIdx ? 0 : prevIdx + 1
            } else {
                // direction is up
                return prevIdx === 0 ? lastIdx : prevIdx - 1
            }
        },
        [
            addItemOptions.length,
            filteredItems.length,
            isTermValidForNewGlossary,
            withFlatAddItemOptions,
        ],
    )

    const handleDownArrow = useCallback(
        (e: Event) => {
            if (anySubmenuExpanded) {
                if (isGlossaryModalOpen) {
                    e.stopPropagation()
                }

                return
            }

            searchInputRef.current?.setSelectionRange(searchTerm.length, searchTerm.length)
            setActiveItemIdx((prevIdx) => getNextActiveItemIdx(prevIdx, 'down'))
            setActiveItemSource('keyboard')
        },
        [anySubmenuExpanded, searchTerm.length, isGlossaryModalOpen, getNextActiveItemIdx],
    )

    const handleUpArrow = useCallback(
        (e: Event) => {
            if (anySubmenuExpanded) {
                if (isGlossaryModalOpen) {
                    e.stopPropagation()
                }
                return
            }

            searchInputRef.current?.setSelectionRange(searchTerm.length, searchTerm.length)
            setActiveItemIdx((prevIdx) => getNextActiveItemIdx(prevIdx, 'up'))
            setActiveItemSource('keyboard')
        },
        [anySubmenuExpanded, searchTerm.length, isGlossaryModalOpen, getNextActiveItemIdx],
    )

    const onOpenSubMenu = useCallback(() => {
        if (!withExpandableItems) {
            return
        }

        const searchCursorOffset = Math.max(
            searchInputRef.current?.selectionStart ?? 0,
            searchInputRef.current?.selectionEnd ?? 0,
        )
        if (isGlossaryModalOpen || searchCursorOffset !== searchTerm.length) {
            return
        }

        if (isAddItemActive) {
            setIsAddItemExpanded(true)
        } else {
            setExpandedItemIdx(activeItemIdx)
        }

        setActiveItemSource('keyboard')
    }, [
        withExpandableItems,
        isGlossaryModalOpen,
        searchTerm.length,
        isAddItemActive,
        activeItemIdx,
    ])

    const onCloseSubMenu = useCallback(
        (e: Event) => {
            if (isGlossaryModalOpen) {
                return
            }

            const searchCursorOffset = Math.max(
                searchInputRef.current?.selectionStart ?? 0,
                searchInputRef.current?.selectionEnd ?? 0,
            )
            if (searchCursorOffset === searchTerm.length && anySubmenuExpanded) {
                e.preventDefault()
            }

            setIsAddItemExpanded(false)
            setExpandedItemIdx(-1)
            setActiveItemSource('keyboard')
        },
        [anySubmenuExpanded, isGlossaryModalOpen, searchTerm.length],
    )

    const handleEnter = useCallback(() => {
        if (!anySubmenuExpanded && !isAddItemActive && searchTerm.length) {
            if (withFlatAddItemOptions && activeItemIdx > filteredItems.length - 1) {
                const addItemOptionIdx = activeItemIdx - filteredItems.length

                if (!addItemOptions[addItemOptionIdx].disabled) {
                    handleTermAddRequest(searchTerm, glossaryCategories[addItemOptionIdx])
                }
            } else {
                const term = filteredItems[activeItemIdx]
                onTermSelected?.(term)
                analytics?.sendUseGlossaryTerm(
                    taskId ?? '',
                    term.id,
                    term.category,
                    term.text,
                    term.verified ?? false,
                    source,
                    initialSearchTerm,
                )
            }
        }
    }, [
        anySubmenuExpanded,
        isAddItemActive,
        searchTerm,
        withFlatAddItemOptions,
        activeItemIdx,
        filteredItems,
        addItemOptions,
        handleTermAddRequest,
        onTermSelected,
        analytics,
        taskId,
        source,
        initialSearchTerm,
    ])

    const handleEscape = useCallback(
        (e: Event) => {
            if (isGlossaryModalOpen) {
                e.stopPropagation()

                closeModal()
                setIsAddItemExpanded(false)
                setExpandedItemIdx(-1)
                setActiveItemSource('keyboard')
                searchInputRef.current?.focus()
            }
        },
        [closeModal, isGlossaryModalOpen],
    )

    useMousetrap('down', handleDownArrow, {
        preventDefault: true,
        allowRepeat: true,
        condition: withShortcuts,
    })
    useMousetrap('up', handleUpArrow, {
        preventDefault: true,
        allowRepeat: true,
        condition: withShortcuts,
    })
    useMousetrap('right', submenuSide === 'left' ? onCloseSubMenu : onOpenSubMenu, {
        preventDefault: false,
        allowRepeat: false,
        condition: withShortcuts,
    })
    useMousetrap('left', submenuSide === 'left' ? onOpenSubMenu : onCloseSubMenu, {
        preventDefault: false,
        allowRepeat: false,
        condition: withShortcuts,
    })
    useMousetrap('enter', handleEnter, {
        preventDefault: true,
        allowRepeat: false,
        condition: withShortcuts,
    })
    useMousetrap('esc', handleEscape, {
        condition: withShortcuts,
    })

    useEffect(() => {
        setSearchTerm(initialSearchTerm)
    }, [initialSearchTerm])

    useEffect(() => {
        itemRefs.current = itemRefs.current.slice(0, filteredItems.length)
    }, [filteredItems])

    useEffect(() => {
        if (activeItemSource === 'keyboard') {
            itemRefs.current[activeItemIdx]?.scrollIntoView({ block: 'nearest' })
        }
    }, [activeItemIdx, activeItemSource])

    // autofocus doesn't work well after the second time the glossary is being open, this problem needs more investigation.
    // using focus after the component mounted is a quick fix that solves it.
    useEffect(() => {
        setTimeout(() => {
            searchInputRef.current?.focus()
        }, 250)
    }, [])

    return {
        searchTerm,
        items: glossary.terms,
        filteredItems,
        addItemOptions,

        activeItemIdx,
        isAddItemActive: activeItemIdx === filteredItems.length,
        expandedItemIdx,
        isAddItemExpanded,
        displayAddItem,

        itemRefs,
        searchInputRef,

        handleSearchInputChange,
        handleTermEditRequest,
        handleTermDeleteRequest,
        handleItemMouseOver: handleGlossaryItemMouseOver,
        handleItemClick,
        handleAddItemMouseOver,
    }
}
