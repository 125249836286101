import { useEffect } from 'react'
import { Node } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { EventsList } from 'src/models/events'
import { useEventsMarking } from 'src/state/EventsProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'

export default function useEditorEvents() {
    const editor = useSlateStatic()
    const { setEventsList, targetIndex, setTargetIndex } = useEventsMarking()
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')
    const eventsLibTabEnabled = useFeatureFlag('events_list_tab')

    // Scroll to the target index when it changes, triggered by user's target selection from side panel
    useEffect(() => {
        // explicitly check for undefined value only because 0 is a valid index
        // so we cannot use !targetIndex which will include undefined, null, 0, false, "" (empty string), or NaN
        // because all of these values are falsy.
        if (targetIndex === undefined) return
        const el = ReactEditor.toDOMNode(editor, Node.get(editor, [targetIndex]))
        el.scrollIntoView({ block: 'center', inline: 'nearest' })

        // set the target index to undefined after scrolling to it
        // to allow user clicking the same target again
        // this eliminates the feeling of target being stuck
        setTimeout(() => {
            setTargetIndex && setTargetIndex(undefined)
        }, 1000)
    }, [targetIndex, editor, setTargetIndex])

    // this is responsible for updating the events list in the state manager
    // whenever the editor content changes (e.g. when a section is added, removed, or modified)
    // or when the events marking feature flag changes.
    // Also updates the events list when transcript comes with section/event in it
    useEffect(() => {
        if (!eventsMarkingFlag && !eventsLibTabEnabled) return
        editor.children.forEach((block, index) => {
            const { section, mediaTimeCode } = block as { section: string; mediaTimeCode: string }

            if (section) {
                setEventsList((prev: EventsList[]) => {
                    // Find if an event with the same `index` already exists
                    const eventIndex = prev.findIndex((event) => event.index === index)

                    // If the event exists, check if the section has changed
                    if (eventIndex !== -1) {
                        const existingEvent = prev[eventIndex]

                        // If the section has changed, update the event
                        if (existingEvent.section !== section) {
                            const updatedList = [...prev]
                            updatedList[eventIndex] = {
                                ...existingEvent, // Keep other properties (like mediaTimeCode) unchanged
                                section, // Update the section
                            }

                            return updatedList // Return the updated list with the modified event
                        }

                        return prev // If section hasn't changed, return the previous list
                    }

                    // If the event doesn't exist, add the new event to the list
                    const updatedList = [
                        ...prev,
                        {
                            index,
                            mediaTimeCode,
                            section,
                        } as EventsList,
                    ]

                    return updatedList.sort((a, b) => a.index - b.index)
                })
            } else {
                // when section is undefined meaning it was deleted or removed in the editor then removed also in the state manager
                setEventsList((prev: EventsList[]) => {
                    const eventIndex = prev.findIndex((event) => event.index === index)
                    if (eventIndex !== -1) {
                        const updatedList = [...prev]
                        updatedList.splice(eventIndex, 1)
                        return updatedList
                    }
                    return prev
                })
            }
        })
    }, [eventsMarkingFlag, eventsLibTabEnabled, editor, editor.children, setEventsList])
}
