import React, { useRef } from 'react'

import { SessionChatPanel } from 'src/components/Session/panels/SessionChatPanel/SessionChatPanel'
import { GlossaryPanel } from 'src/components/Session/panels/GlossaryPanel/GlossaryPanel'
import { SpeakerPanel } from 'src/components/Session/panels/SpeakerPanel/SpeakerPanel'
import { EventsPanel } from 'src/components/Session/panels/EventsPanel/EventsPanel'
import { AttachmentsPanel } from 'src/components/Session/panels/AttachmentsPanel/AttachmentsPanel'
import { ShortcutPanel } from 'src/components/Session/panels/ShortcutPanel/ShortcutPanel'
import { PanelTab, usePanelTabs } from 'src/components/Session/PanelTabsManager'
import { defaultLivePanelTab } from 'src/components/Session/live/components/LivePanelTabsManager/consts'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { useTaskMediaPlayer } from 'src/state/TaskAudioContext'
import { ActivePanelWrapper, PanelContainer } from './styles'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'

export const LivePanelTabsManager = () => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { isPlayerVisible } = useTaskMediaPlayer(['isPlayerVisible'])
    const { activePanelTab, setActivePanelTab } = usePanelTabs()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    isRealTimeReadOnly && setActivePanelTab({ name: 'EVENTS' })
    const ActivePanelTab = getActivePanelTab(activePanelTab ?? defaultLivePanelTab)
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')

    return (
        <PanelContainer
            ref={(el) => {
                containerRef.current = el
            }}
            isAudioPlayerVisible={isPlayerVisible}
            $isRealTimeReadOnly={isRealTimeReadOnly}
            $eventsMarkingFlag={eventsMarkingFlag}
        >
            <ActivePanelWrapper>
                <ActivePanelTab {...activePanelTab?.props} />
            </ActivePanelWrapper>
        </PanelContainer>
    )
}

function getActivePanelTab(panelTab: PanelTab) {
    switch (panelTab.name) {
        case 'SESSION_CHAT':
            return SessionChatPanel

        case 'GLOSSARY':
            return GlossaryPanel

        case 'SPEAKERS':
            return SpeakerPanel

        case 'EVENTS':
            return EventsPanel

        case 'ATTACHMENTS':
            return AttachmentsPanel

        case 'SHORTCUTS':
            return ShortcutPanel
    }
}
