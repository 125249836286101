import { Editor, Text } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { useTheme } from 'styled-components/macro'
import { palette } from 'styled-tools'

import { useAnalytics } from 'src/analytics'
import { useContextMenuBuilder } from 'src/components/ContextMenu/ContextMenuBuilder'
import { Suggestion } from 'src/components/Editor/plugins/withSuggestions/Suggestion'
import { getTermCategoryIcon } from 'src/components/Glossary/GlossaryItem'
import { useGlossary } from 'src/state/GlossaryProvider'
import * as Icons from 'src/components/icons'

import { Tag } from '../withTags/Tag'
import { GlossaryVerifiedMark } from 'src/components/Glossary/GlossaryVerifiedMark'
import { useEditorMeta } from '../../EditorContext'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'

const SuggestionLabel = styled.span`
    font-weight: 600;
    color: ${palette('navy', 0)};

    display: flex;
    align-items: center;
`

/**
 * This hook adds all the necessary context menu items regarding the suggestions.
 */
export function useSuggestionsContextMenu() {
    const editor = useSlateStatic()
    const analytics = useAnalytics()
    const { taskId } = useEditorMeta()
    const { glossary } = useGlossary()
    const theme = useTheme()
    const isRealTimeReadOnly = useIsReadOnlyMode()

    useContextMenuBuilder(
        'suggestion',
        (menu, [node], range) => {
            if (isRealTimeReadOnly) return

            const SUGGESTIONS_SECTION = 'SUGGESTIONS_SECTION'
            menu.setSection(SUGGESTIONS_SECTION, { items: [], order: 1 })

            const normalizedRange = { ...range }

            // if the range ends at the beginning of a new node, exclude it
            if (range.focus.offset === 0) {
                const normalizedFocus = Editor.before(editor, range.focus, {
                    unit: 'offset',
                    distance: 1,
                })

                if (normalizedFocus) {
                    normalizedRange.focus = normalizedFocus
                }
            }

            let totalTextNodes = Array.from(
                Editor.nodes(editor, { at: normalizedRange, match: Text.isText }),
            )

            // only show suggestions if the suggestion is the only thing that is selected
            if (totalTextNodes.length === 1 && Suggestion.isSuggestion(node)) {
                analytics?.sendShowGlossarySuggestions(taskId ?? '')

                const glossaryIds = node.suggestion.glossaryIds
                glossaryIds
                    .filter((glossId, i) => glossaryIds.indexOf(glossId) === i) // filter duplicate ids
                    .forEach((glossId) => {
                        const glossaryItem = glossary.terms.find((term) => term.id === glossId)

                        if (!glossaryItem) return

                        menu.addItem(
                            {
                                id: glossaryItem.text,
                                label: (
                                    <SuggestionLabel>
                                        {glossaryItem.text}
                                        {glossaryItem.verified && <GlossaryVerifiedMark />}
                                    </SuggestionLabel>
                                ),
                                icon: getTermCategoryIcon(glossaryItem.category, {
                                    color: theme.palette.navy[2],
                                }),
                                onAction: (editor, range) => {
                                    if (range.current) {
                                        let originalText
                                        if (Text.isText(node.children?.[0])) {
                                            originalText = node.children[0].text
                                        }

                                        analytics?.sendUseSuggestion(
                                            taskId ?? '',
                                            glossId,
                                            glossaryItem.category,
                                            glossaryItem.text,
                                            glossaryItem.verified ?? false,
                                            originalText,
                                        )
                                        Suggestion.unwrapSuggestion(editor, { at: range.current })
                                        Tag.insertTag(
                                            editor,
                                            { type: 'glossary', glossary: glossaryItem },
                                            { at: range.current, contents: glossaryItem.text },
                                        )
                                    }
                                },
                            },
                            SUGGESTIONS_SECTION,
                        )
                    })
            }

            if (!menu.hasItemWithId('removeSuggestion')) {
                menu.addItem({
                    order: 10,
                    id: 'removeSuggestion',
                    label: 'Remove Suggestion',
                    icon: <Icons.RemoveIcon />,
                    onAction: (editor, range) => {
                        if (range.current && Suggestion.isSuggestion(node)) {
                            let originalText
                            const suggestionId = node.suggestion.glossaryIds[0]
                            const glossaryItem = glossary.terms.find(
                                (term) => term.id === suggestionId,
                            )
                            const suggestionText = glossaryItem?.text

                            if (Text.isText(node.children?.[0])) {
                                originalText = node.children[0].text
                            }

                            analytics?.sendClearGlossarySuggestion(
                                taskId ?? '',
                                glossaryItem?.verified ?? false,
                                originalText,
                                suggestionText,
                            )
                            Suggestion.unwrapSuggestion(editor, { at: range.current })
                        }
                    },
                })
            }
        },
        [glossary.terms],
    )
}
