import React, { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react'

import styled, { css, keyframes } from 'styled-components/macro'
import { ifProp, palette, prop, theme } from 'styled-tools'

import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { DraggableVideoPlayer } from 'src/components/VideoPlayer/DraggableVideoPlayer'
import { SessionChatPanel } from 'src/components/Session/panels/SessionChatPanel/SessionChatPanel'
import { GlossaryPanel } from 'src/components/Session/panels/GlossaryPanel/GlossaryPanel'
import { SpeakerPanel } from 'src/components/Session/panels/SpeakerPanel/SpeakerPanel'
import { EventsPanel } from './panels/EventsPanel/EventsPanel'
import { AttachmentsPanel } from 'src/components/Session/panels/AttachmentsPanel/AttachmentsPanel'
import { ShortcutPanel } from 'src/components/Session/panels/ShortcutPanel/ShortcutPanel'
import { useVideo } from 'src/state/VideoProvider'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'
import { useVideoSnappedDropZone } from '../VideoPlayer/useVideoSnappedDropZone'
import { useTaskMediaPlayer } from 'src/state/TaskAudioContext'

type Position = 'left' | 'right'

const slideInDown = keyframes`
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
`

const slideOutUp = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
`

const PanelContainer = styled.div<{
    isAudioPlayerVisible: boolean
    position: Position
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    ${prop('position')}: 0;
    bottom: 0;
    height: ${ifProp(
        'isAudioPlayerVisible',
        css`calc(100% - ${theme('sizes.audioPlayerHeight')}px)`,
        '100%',
    )};
    width: 22%;
    min-width: 280px;
    max-width: 422px;
`

const ActivePanelWrapper = styled.div<{
    isClosing: boolean
    videoHeight?: number
}>`
    height: ${ifProp('videoHeight', css`calc(100% - ${prop('videoHeight')}px)`, '100%')};
    background-color: white;
    border: solid 1px ${palette('grey', 13)};
    z-index: ${theme('zIndexes.sidePanel')};
    animation: ${ifProp('isClosing', slideOutUp, slideInDown)} 500ms cubic-bezier(0.25, 1, 0.5, 1)
        forwards;
`

const DroppableZone = styled.div`
    height: calc(30vh - 63px);
    align-items: center;
    margin: 10px;
    z-index: ${theme('zIndexes.droppableZone')};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border: dashed 1px ${palette('cloudBlue', 2)};
    background-color: ${palette('white', 0)};
`

const DropTitle = styled.span`
    color: #5c7199;
    font-size: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const VideoPlayerContainer = styled.div<{ height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${prop('height')}px;
    z-index: ${theme('zIndexes.videoPlayer')};
`

export interface PanelTabOption<Name extends string, Props extends {}> {
    name: Name
    props?: { key?: string } & Props
}

export type PanelTab =
    | PanelTabOption<'SESSION_CHAT', {}>
    | PanelTabOption<'GLOSSARY', {}>
    | PanelTabOption<'SPEAKERS', {}>
    | PanelTabOption<'EVENTS', {}>
    | PanelTabOption<'ATTACHMENTS', {}>
    | PanelTabOption<'SHORTCUTS', {}>

interface TabsPanelProps {
    position: Position
}

export const PanelTabsManager = ({ position }: TabsPanelProps) => {
    const [{ context, value: taskState }] = useTaskMachine(['state', 'task'])
    const { task } = context

    const { isPlayerVisible } = useTaskMediaPlayer(['isPlayerVisible'])
    const { isVideoSnappedIntoPosition, isValidVideo, calculateVideoDimensions } = useVideo()
    const { activePanelTab, prevActivePanelTab, resetPrevActivePanelTab } = usePanelTabs()
    const tabBeingClosed = !activePanelTab && prevActivePanelTab
    const renderedTab = tabBeingClosed || activePanelTab

    const [{ isVideoOver }, drop] = useVideoSnappedDropZone()

    const containerRef = useRef<HTMLDivElement | null>(null)
    const containerRect = useBoundingClientRect(containerRef)

    const videoHeight = useMemo(
        () => (isValidVideo ? calculateVideoDimensions('height', containerRect.width) : undefined),
        [calculateVideoDimensions, containerRect.width, isValidVideo],
    )

    const ActivePanelTab = renderedTab ? getActivePanelTab(renderedTab) : () => <></>
    const showDropZone = isVideoOver && !isVideoSnappedIntoPosition

    return (
        <>
            <PanelContainer
                ref={(el) => {
                    drop(el)
                    containerRef.current = el
                }}
                position={position}
                isAudioPlayerVisible={isPlayerVisible}
            >
                {renderedTab ? (
                    <ActivePanelWrapper
                        isClosing={!!tabBeingClosed}
                        videoHeight={
                            isValidVideo && isVideoSnappedIntoPosition ? videoHeight : undefined
                        }
                        onAnimationEnd={() => {
                            if (tabBeingClosed) {
                                resetPrevActivePanelTab()
                            }
                        }}
                    >
                        <ActivePanelTab {...activePanelTab?.props} />
                    </ActivePanelWrapper>
                ) : (
                    <div />
                )}
                {isValidVideo && taskState === 'in-progress' && task?.type !== 'onboarding' && (
                    <>
                        {isVideoSnappedIntoPosition ? (
                            <VideoPlayerContainer height={videoHeight}>
                                <DraggableVideoPlayer
                                    width={containerRect.width}
                                    height={videoHeight}
                                />
                            </VideoPlayerContainer>
                        ) : (
                            showDropZone && (
                                <DroppableZone>
                                    <DropTitle>Drop Video</DropTitle>
                                </DroppableZone>
                            )
                        )}
                    </>
                )}
            </PanelContainer>
        </>
    )
}

function getActivePanelTab(panelTab: PanelTab) {
    switch (panelTab.name) {
        case 'SESSION_CHAT':
            return SessionChatPanel

        case 'GLOSSARY':
            return GlossaryPanel

        case 'SPEAKERS':
            return SpeakerPanel

        case 'EVENTS':
            return EventsPanel

        case 'ATTACHMENTS':
            return AttachmentsPanel

        case 'SHORTCUTS':
            return ShortcutPanel
    }
}

const PanelTabsContext = React.createContext<{
    activePanelTab: PanelTab | null
    prevActivePanelTab: PanelTab | null
    setActivePanelTab: (panelTab: PanelTab | null, forceRerender?: boolean) => void
    resetPrevActivePanelTab: () => void
}>({
    activePanelTab: null,
    prevActivePanelTab: null,
    setActivePanelTab: () => {},
    resetPrevActivePanelTab: () => {},
})

interface PanelTabsProviderProps {
    children: ReactNode
    initialTab?: PanelTab | null
}

export const PanelTabsProvider = ({ children, initialTab = null }: PanelTabsProviderProps) => {
    const [{ activePanelTab, prevActivePanelTab }, setPanelTabsState] = useState<{
        activePanelTab: PanelTab | null
        prevActivePanelTab: PanelTab | null
    }>({
        activePanelTab: initialTab,
        prevActivePanelTab: null,
    })

    const setActivePanelTab = useCallback(
        (nextActivePanelTab: PanelTab | null) =>
            setPanelTabsState(({ activePanelTab }) => ({
                activePanelTab: nextActivePanelTab,
                prevActivePanelTab: activePanelTab,
            })),
        [],
    )

    const resetPrevActivePanelTab = useCallback(
        () =>
            setPanelTabsState((state) => ({
                ...state,
                prevActivePanelTab: null,
            })),
        [],
    )

    return (
        <PanelTabsContext.Provider
            value={useMemo(
                () => ({
                    activePanelTab,
                    prevActivePanelTab,
                    setActivePanelTab,
                    resetPrevActivePanelTab,
                }),
                [activePanelTab, prevActivePanelTab, setActivePanelTab, resetPrevActivePanelTab],
            )}
        >
            {children}
        </PanelTabsContext.Provider>
    )
}

export const usePanelTabs = () => useContext(PanelTabsContext)

export const useIsPanelTabsOpen = () => {
    const { activePanelTab } = usePanelTabs()
    return !!activePanelTab
}
