import { useMemo } from 'react'
import {
    GlossaryIcon,
    UsersIcon,
    ListIcon,
    AttachmentIcon,
    KeyboardIcon,
} from '@verbit-ai/icons-library'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { useChat } from 'src/state/ChatProvider'
import { PanelTabButtonProps } from 'src/components/Session/types'
import {
    TaskBarButton,
    TaskBarButtons,
    TaskBarContainer,
    TaskBarContent,
    TaskBarTitle,
} from 'src/components/Session/styles'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { usePanelTabs } from 'src/components/Session/PanelTabsManager'
import { TaskToolbar } from 'src/components/Toolbar'
import { theme } from 'src/components/styled'

export interface LivePanelTabsHeaderProps {
    title?: string
}

export function LivePanelTabsHeader({ title }: LivePanelTabsHeaderProps) {
    const sessionId = useSessionStatus(['sessionStatus.sessionId']).sessionStatus?.sessionId
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const { activePanelTab, setActivePanelTab } = usePanelTabs()
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')
    const eventsLibTabEnabled = useFeatureFlag('events_list_tab')
    const { channelUnreadCounters } = useChat()
    const { unreadMessageCount = 0, unreadMentionCount = 0 } =
        channelUnreadCounters[sessionId ?? ''] ?? {}
    const headerPanelTabs = useMemo<PanelTabButtonProps[]>(
        () => [
            {
                tab: { name: 'GLOSSARY' },
                icon: <GlossaryIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: !isRealTimeReadOnly,
            },
            {
                tab: { name: 'SPEAKERS' },
                icon: <UsersIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: !isRealTimeReadOnly,
            },
            {
                tab: { name: 'EVENTS' },
                icon: <ListIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: eventsMarkingFlag && eventsLibTabEnabled,
            },
            {
                tab: { name: 'ATTACHMENTS' },
                icon: <AttachmentIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: !isRealTimeReadOnly,
            },
            {
                tab: { name: 'SHORTCUTS' },
                icon: <KeyboardIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: !isRealTimeReadOnly,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [unreadMessageCount, unreadMentionCount, eventsMarkingFlag],
    )

    return (
        <TaskBarContainer taskType={'transcription'}>
            <TaskBarContent>
                {title && <TaskBarTitle>{title}</TaskBarTitle>}
                <TaskToolbar />
            </TaskBarContent>
            <TaskBarButtons
                $isRealTimeReadOnly={isRealTimeReadOnly}
                $eventsMarkingFlag={eventsMarkingFlag}
            >
                {headerPanelTabs.map(({ tab, icon, visible = true }) => {
                    const isActive = activePanelTab?.name === tab.name

                    return (
                        <TaskBarButton
                            data-testid={`task-bar-button-${tab.name}`}
                            key={tab.name}
                            isVisible={visible}
                            isActive={isActive}
                            onClick={() => setActivePanelTab(tab)}
                            highlightBackground
                        >
                            {icon}
                        </TaskBarButton>
                    )
                })}
            </TaskBarButtons>
        </TaskBarContainer>
    )
}
