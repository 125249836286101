import { Editor, RangeRef } from 'slate'
import { debounce } from 'lodash'

import { EditorControls, SubmitSegmentPayload } from 'src/models'
import { getWordsListFromBlocks } from '../../convert-to-publish'
import { Block } from '../withTranscript'
import { LiveEditsEditor } from './LiveEditsEditor'
import { mockManager } from 'src/network/mocks'
import { validateTimelineData } from '../withTimeline/validateTimelineData'
import {
    ChangedSegment,
    getChangedSegments,
    trackModificationByOperation,
} from './BlockModificationObject'

export interface LiveEditsProps {
    enableSubmit?: boolean
    onSubmit: (payload: SubmitSegmentPayload) => Promise<boolean>
    onError: (err: any) => void
    controls: EditorControls
    debounceTime: number
    lastWebsocketRangeRef: React.MutableRefObject<RangeRef | null>
    eventsMarkingFlag: boolean
}

function resetModificationsObject(editor: Editor) {
    editor.liveModifications = null
}

function prepareSubmitSegmentPayload(
    editor: Editor,
    controls: EditorControls,
    segment: ChangedSegment,
    eventsMarkingFlag: boolean,
): SubmitSegmentPayload {
    const { startBlockIndex, endBlockIndex } = segment

    const blocks = Array.from(
        Editor.nodes(editor, {
            at: [[startBlockIndex], [endBlockIndex]],
            match: Block.isBlock,
        }),
    )

    const segmentStartTime = editor.timeline.getBlockStartTime(startBlockIndex)
    const segmentEndTime = editor.timeline.getBlockEndTime(endBlockIndex)
    const wordsList = getWordsListFromBlocks(
        editor,
        blocks,
        controls,
        segmentStartTime,
        eventsMarkingFlag,
    )

    if (segmentEndTime <= segmentStartTime) {
        throw new Error('Segment end time is less than or equal to segment start time on submit')
    }

    validateTimelineData(editor)

    const response = {
        start: segmentStartTime,
        end: segmentEndTime,
        words: wordsList,
    }

    return response
}

export function withLiveEdits({
    debounceTime,
    onSubmit,
    onError,
    enableSubmit = true,
    controls,
    lastWebsocketRangeRef,
    eventsMarkingFlag,
}: LiveEditsProps) {
    return (editor: Editor) => {
        const { onChange, apply, decorate } = editor
        editor.liveModifications = null

        const debouncedFunc = debounce(() => {
            if (!editor.liveModifications) throw new Error('No live modifications object found')

            try {
                const changedSegments = getChangedSegments(
                    editor.liveModifications,
                    editor.children.length - 1,
                )
                for (const segment of changedSegments) {
                    const submitSegmentPayload: SubmitSegmentPayload = prepareSubmitSegmentPayload(
                        editor,
                        controls,
                        segment,
                        eventsMarkingFlag,
                    )
                    onSubmit(submitSegmentPayload)
                }

                console.debug('[MOCK] submit segment mock dump', mockManager.getMockData())
                resetModificationsObject(editor)
            } catch (err: any) {
                onError(err)
            }
        }, debounceTime)

        editor.apply = (operation) => {
            apply(operation)

            if (!LiveEditsEditor.shouldAllowLiveEdits(editor) || !enableSubmit) {
                return
            }

            editor.liveModifications = trackModificationByOperation(
                editor.liveModifications,
                operation,
            )
        }

        editor.onChange = () => {
            if (editor.liveModifications && enableSubmit) {
                debouncedFunc()
            }

            onChange()
        }

        editor.decorate = (entry) => {
            const [node] = entry
            const ranges = decorate(entry)

            if (!Editor.isEditor(node)) {
                return ranges
            }

            if (lastWebsocketRangeRef.current?.current) {
                ranges.push({ ...lastWebsocketRangeRef.current.current, liveUpdate: true })
            }

            return ranges
        }

        return editor
    }
}
