import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { useMousetrap } from 'src/hooks/useMousetrap'

export default function useEditorTabNavigation() {
    const editor = useSlateStatic()

    const handleEditorTabNavigation = (e: KeyboardEvent) => {
        e.preventDefault()
        if (!editor.selection) return

        const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
        const [positionIndex] = blockPath

        if (e.key.toLocaleUpperCase() === 'TAB') {
            const focusedElement = document.activeElement as HTMLElement

            let nextElement: any
            if (focusedElement?.className.includes('add-events-')) {
                nextElement = document.getElementsByClassName(
                    `add-exhibit-${positionIndex}`,
                ) as unknown as HTMLElement
            } else if (focusedElement?.className.includes('add-exhibit-')) {
                nextElement = document.getElementsByClassName(
                    `legal-annotation-select-${positionIndex}`,
                ) as unknown as HTMLElement
            } else if (focusedElement?.className.includes('legal-annotation-select-')) {
                nextElement = document.getElementsByClassName(
                    `btn-speaker-select-${positionIndex}`,
                ) as unknown as HTMLElement
            } else if (focusedElement?.className.includes('btn-speaker-select-')) {
                nextElement = document.getElementsByClassName(
                    `content-view-${positionIndex}`,
                ) as unknown as HTMLElement
            } else if (focusedElement?.className.includes('content-view-')) {
                nextElement = document.getElementsByClassName(
                    `add-events-${positionIndex}`,
                ) as unknown as HTMLElement
            } else {
                nextElement = document.getElementsByClassName(
                    `add-exhibit-${positionIndex}`,
                ) as unknown as HTMLElement
            }

            if (nextElement) {
                if (document.activeElement instanceof HTMLElement) {
                    nextElement?.[0]?.focus()
                }
            }
        }
    }

    useMousetrap('tab', handleEditorTabNavigation, {
        label: 'Editor Tab Navigation',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
}
