import { Node, Editor, NodeEntry, Text } from 'slate'

import { TimingRange } from 'src/utils/range'
import {
    EditorControls,
    MenuItemInterface,
    TranscriptionTask,
    WordJSON,
    EventsMenuItem,
} from 'src/models'
import { SuggestionTagData, TagData } from 'src/models/tag'
import { Block } from 'src/components/Editor/plugins/withTranscript'

import { ZERO_WIDTH_WHITESPACE_REGEX } from 'src/utils/string'
import { EMPTY_TAG_PLACEHOLDERS, Tag } from './plugins/withTags/Tag'

type OutputTagData = Exclude<TagData, SuggestionTagData>

export function getWordsListFromBlocks(
    editor: Editor,
    blocks: NodeEntry<Block>[],
    controls: EditorControls,
    blockStartTime: number,
    eventsMarkingFlag: boolean,
) {
    let label: string | undefined
    let eventsMap: { [value: string]: EventsMenuItem } = {}
    let sectionMap: { [key: string]: MenuItemInterface } = {}
    const wordlist: WordJSON[] = []

    if (eventsMarkingFlag) {
        const eventItems = controls.events?.items ?? []
        eventsMap = eventItems
            .flatMap((group) => group.options)
            .reduce((map, item) => ({ ...map, [item.value]: item }), {})
    } else {
        const sectionItems = controls.section.items ?? []
        sectionMap = sectionItems.flat().reduce((map, item) => ({ ...map, [item.key]: item }), {})
    }

    for (const [block, blockPath] of blocks) {
        // Push a special 'section' word if the block has a section
        if (block.section) {
            if (eventsMarkingFlag) {
                // If the section is an event, use the event label if it exists,
                // otherwise use the section name to catch the latest label if the section was changed
                if (eventsMap[block.section]?.label) {
                    label = eventsMap[block.section]?.label
                } else {
                    label = block.section
                }
            } else {
                label = sectionMap[block.section]?.label
            }

            wordlist.push({
                word: label,
                type: 'section',
                speaker_id: null,
                section: {
                    text: label,
                    type: block.section,
                },
                start:
                    (eventsMarkingFlag ? block?.start : block.sectionMediaTime) ?? blockStartTime,
                end: (eventsMarkingFlag ? block?.start : block.sectionMediaTime) ?? blockStartTime,
                ...(block.bitcTimeCode && { timecode: block.bitcTimeCode }),
            })
        }

        // Search for text or void tag nodes (since they're the lowest-level nodes that we consider as "having text")
        // but exclude text nodes that only have a zero-width whitespace as their content (these can get created before tags)
        const leaves = Array.from(
            Editor.nodes(editor, {
                at: blockPath,
                match: (n) => (Text.isText(n) && /\S/.test(n.text)) || Tag.isVoidTag(n), // '\S' is any non-whitespace character
            }),
        )

        for (const [leafIndex, [leaf, leafPath]] of leaves.entries()) {
            if (Text.isText(leaf)) {
                const [tagNode] = Editor.parent(editor, leafPath)
                const tag: OutputTagData | undefined = Tag.isTag(tagNode)
                    ? convertTag(tagNode)
                    : undefined

                const text = Node.string(leaf)
                let formattedText = text.replace(ZERO_WIDTH_WHITESPACE_REGEX, '') // replace zero-widths from tags with nothing

                if (Tag.isTag(tagNode) && tagNode.hasPlaceholder) {
                    formattedText = formattedText.replace(
                        Tag.getPlaceholderText(editor, tagNode.tagType),
                        EMPTY_TAG_PLACEHOLDERS[tagNode.tagType] ?? '',
                    )
                }

                const words = formattedText.split(/\s+/g).filter((w) => w !== '')

                for (const [wordIndex, word] of words.entries()) {
                    const isFirstWordOfFirstLeafOfBlock = leafIndex === 0 && wordIndex === 0
                    // First word of the first leaf of the first block should be marked as break only if it was already marked on the task itself.
                    // First word of the first leaf of any block after the first one should be marked as break.
                    const markAsBreak = isFirstWordOfFirstLeafOfBlock && block.hasExplicitBreak

                    wordlist.push({
                        word,
                        type: 'word',
                        start:
                            (eventsMarkingFlag ? block?.start : blockStartTime) ?? blockStartTime,
                        end: (eventsMarkingFlag ? block?.start : blockStartTime) ?? blockStartTime,
                        speaker_id: block.speakerId,
                        legal: block.legal,
                        break: markAsBreak ? true : undefined, // the API expects either `break: true` or no key at all
                        tag,
                    })
                }
            } else if (Tag.isVoidTag(leaf)) {
                const tag: OutputTagData | undefined = convertTag(leaf)

                if (Tag.isLabelTag(leaf)) {
                    const markAsBreak = leafIndex === 0 && block.hasExplicitBreak

                    wordlist.push({
                        word: leaf.label.text,
                        type: 'label',
                        start: 0,
                        end: 0,
                        speaker_id: block.speakerId,
                        legal: block.legal,
                        break: markAsBreak ? true : undefined, // the API expects either `break: true` or no key at all
                        tag,
                    })
                }
            }
        }
    }

    return wordlist
}

export function convertEditorValueToPublishingData(
    editor: Editor,
    task: TranscriptionTask,
    taskAudioPlayedRanges: TimingRange[],
) {
    // events marking flag is false by default in non-live tasks
    const eventsMarkingFlag = task.payload.controls.events?.visible ?? false
    const editableBlocks: NodeEntry<Block>[] = Array.from(
        Editor.nodes(editor, {
            at: [],
            mode: 'highest',
            match: (n) => Block.isBlock(n) && n.editable,
        }),
    )

    const wordlist: WordJSON[] = getWordsListFromBlocks(
        editor,
        editableBlocks,
        task.payload.controls,
        task.payload.text.editable.timing.start,
        eventsMarkingFlag,
    )

    return {
        segments: {
            body: {
                start: task.payload.text.editable.timing.start,
                end: task.payload.text.editable.timing.end,
                words: wordlist,
            },
        },
        listened_audio: task.payload.controls.audio.reportListened
            ? taskAudioPlayedRanges
            : undefined,
    }
}

function convertTag(tagNode: Tag): OutputTagData | undefined {
    const { seq } = tagNode

    switch (tagNode.tagType) {
        case 'unclear':
            return { seq, type: 'unclear' }
        case 'glossary':
            return { seq, type: 'glossary', glossary: tagNode.glossary }
        case 'label':
            return { seq, type: 'label', label: tagNode.label }
        default:
            return undefined
    }
}
