import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { ReactEditor } from 'slate-react'
import { Editor } from 'slate'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { Button, InputGroup, Intent, Icon } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Box } from '@chakra-ui/react'
import { IconButton, Tooltip } from '@verbit-ai/verbit-ui-library'
import { PaperIcon, AddPaperIcon } from '@verbit-ai/icons-library'
import styled, { css } from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { LegalExhibit } from 'src/models/legal'
import { useExhibits } from 'src/state/ExhibitsProvider'
import { isAllWhitespace } from 'src/components/Editor/utils'
import { useToast } from 'src/components/Toasts/ToastContext'
import { useAutoScrollContext } from 'src/components/Session/live/components/LiveEditor/AutoScrollContainer'

const Container = styled.div`
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px ${palette('grey', 13)};
    background-color: ${palette('white', 0)};
`

const Row = styled.div<{ reverse?: boolean; first?: boolean; last?: boolean }>`
    display: flex;
    justify-content: flex-${ifProp('reverse', 'end', 'start')};
    align-items: baseline;
    padding: 10px 10px;
    font-size: 14px;
    color: ${palette('black', 0)};

    ${ifProp('first', 'margin-top: 0px;')}
    ${ifProp('last', 'margin-bottom: 0px;')}
`

const Heading = styled(Row)`
    flex: 1;
    padding: 7px 10px;
    font-weight: 600;
    border-bottom: solid 1px ${palette('grey', 13)};
    color: ${palette('black', 0)};
`

const Input = styled(InputGroup)`
    flex: 1;
    margin-left: 10px;

    input {
        box-shadow: none !important;
        padding: 5px 10px;
        background-color: ${palette('white', 0)};
        border-radius: 4px;
        border: solid 1px ${palette('grey', 13)};
        font-size: 14px;
        color: ${palette('black', 0)};

        &:focus {
            box-shadow: none !important;
        }
    }
`

const ActionButton = styled(Button)`
    padding: 5px 11px;
    font-size: 16px;
    min-height: unset;
    min-width: unset;

    &.bp4-button:not([class*='bp4-intent-']) {
        background-color: ${palette('navy', 2)};
        background-image: none;
        box-shadow: none;
        color: ${palette('white', 0)};

        &.negative {
            padding: 0;
            background-color: transparent;
            color: ${palette('navy', 2)};
            text-decoration: underline;
        }
    }

    &:not(:last-of-type) {
        margin-right: 10px;
    }
`

const StyledPopover = styled(Popover2)`
    .bp4-popover-target {
        display: flex;
    }
`

const StyledIconButton = styled(IconButton)<{ $isRealTimeReadOnly?: boolean }>`
    height: 42px !important;
    margin: 0px 10px 0px 24px;
    border: ${ifProp(
        '$isRealTimeReadOnly',
        css`none !important`,
        `solid 1px ${palette('grey', 13)}`,
    )};

    &:active,
    &:focus {
        outline: none;
        pointer-events: none;
    }
`

interface AddExhibitProps {
    exhibit?: LegalExhibit
    disabled: boolean
    onChange: (exhibit?: LegalExhibit) => void
    isRealTimeReadOnly?: boolean
    element: Block
    editor: any
}

export function AddExhibit({
    exhibit,
    onChange,
    disabled,
    isRealTimeReadOnly,
    element,
    editor,
}: AddExhibitProps) {
    const addToast = useToast()
    const [isOpen, setOpen] = useState(false)
    const [exhibitIdentifier, setExhibitIdentifier] = useState(exhibit?.identifier)
    const [blockIndex] = ReactEditor.findPath(editor, element)
    const { isExhibitPopupVisible, setIsExhibitPopupVisible } = useExhibits()
    const { exitAutoScroll } = useAutoScrollContext(['exitAutoScroll'])

    useEffect(() => {
        setExhibitIdentifier(exhibit?.identifier)
    }, [exhibit])

    useEffect(() => {
        if (isExhibitPopupVisible) {
            const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
            const [positionIndex] = blockPath
            if (blockIndex === positionIndex) {
                setOpen(true)
                setIsExhibitPopupVisible(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, isExhibitPopupVisible, blockIndex])

    const onInteraction = useCallback(
        (mode: boolean) => {
            setOpen(mode)

            if (mode) {
                exitAutoScroll()
            }
        },
        [exitAutoScroll],
    )

    const toggleOpen = useCallback(() => {
        onInteraction(!isOpen)
    }, [isOpen, onInteraction])

    const onChangeIdentifier = useCallback(() => {
        if (exhibitIdentifier) {
            const isEmpty =
                exhibitIdentifier.length === 0 ||
                (exhibitIdentifier.length > 0 && isAllWhitespace(exhibitIdentifier))

            if (isEmpty) {
                addToast({
                    intent: Intent.NONE,
                    icon: <Icon icon="issue" color="red" style={{ marginTop: '17px' }} />,
                    message: 'Please enter a valid exhibit number.',
                })
                return
            }

            onChange({ identifier: exhibitIdentifier.trim() })
        }
        toggleOpen()
    }, [exhibitIdentifier, onChange, toggleOpen, addToast])

    const onDeleteExhibit = useCallback(() => {
        onChange(undefined)
        toggleOpen()
    }, [onChange, toggleOpen])

    return (
        <StyledPopover
            key={blockIndex}
            popoverClassName="no-shadow"
            modifiers={{
                arrow: { enabled: false },
                offset: {
                    options: {
                        offset: [0, 12],
                    },
                },
            }}
            isOpen={isOpen}
            disabled={disabled}
            position="bottom-left"
            canEscapeKeyClose
            onInteraction={onInteraction}
            content={
                <Container>
                    <Heading>{exhibit ? 'Edit' : 'Add'} Exhibit</Heading>

                    <Row>
                        <div>Number:</div>
                        <Input
                            type="text"
                            value={exhibitIdentifier ?? ''}
                            autoFocus={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setExhibitIdentifier(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.nativeEvent.code === 'Enter') {
                                    e.stopPropagation()
                                    onChangeIdentifier()
                                }
                            }}
                        />
                    </Row>
                    <Row reverse last>
                        {exhibit && (
                            <ActionButton
                                className="negative"
                                disabled={!exhibit}
                                onClick={onDeleteExhibit}
                            >
                                Remove
                            </ActionButton>
                        )}
                        <ActionButton
                            disabled={!exhibitIdentifier}
                            onClick={onChangeIdentifier}
                            type="submit"
                        >
                            Save
                        </ActionButton>
                    </Row>
                </Container>
            }
        >
            <Tooltip label={getTooltipText(disabled, exhibit)} placement="top" hasArrow>
                <Box>
                    <StyledIconButton
                        id={'add-exhibit-' + blockIndex}
                        className={'add-exhibit-' + blockIndex}
                        variant="light"
                        size="lg"
                        icon={exhibit ? <PaperIcon /> : <AddPaperIcon />}
                        aria-label="exhibit-icon"
                        isDisabled={disabled}
                        $isRealTimeReadOnly={isRealTimeReadOnly}
                    />
                </Box>
            </Tooltip>
        </StyledPopover>
    )
}

function getTooltipText(disabled: boolean, exhibit?: LegalExhibit) {
    if (exhibit) {
        return `Exhibit ${exhibit.identifier}`
    }

    if (disabled) {
        return 'No Exhibits'
    } else {
        return 'Mark an exhibit'
    }
}
