import { Typography } from '@verbit-ai/verbit-ui-library'
import { theme } from '../styled'
import React, { useEffect, useRef, useState } from 'react'
import { useSessionStatus } from '../../state/SessionStatusProvider'
import styled from 'styled-components'

const TimeCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 55px;
    margin-left: 7px;
    background-size: cover;
    background-position: 10px 10px;
`

const appendZinTimeIfNotExist = (str: string) => {
    if (!str.endsWith('Z')) {
        str += 'Z'
    }
    return str
}

export const ElapsedTimeValue = () => {
    const intervalId = useRef<number | null>(null)
    const [elapsedTimeValue, setElapsedTimeValue] = useState<string>('00:00:00')

    const { sessionStatus } = useSessionStatus([
        'sessionStatus.playbackState',
        'sessionStatus.audioStartedAt',
        'sessionStatus.orderId',
    ])

    const elapsedTime = (audio_started_at: string): string => {
        if (!audio_started_at) return '00::00:000'

        // check audio_started_at if it is the correct format
        const formatted_audio_started_at = appendZinTimeIfNotExist(audio_started_at)
        const pastDate = new Date(formatted_audio_started_at)
        const now = new Date()
        const diffInMilliseconds = now.getTime() - pastDate.getTime()
        const hours = Math.floor(diffInMilliseconds / 3600000)
        const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000)
        const seconds = Math.floor(((diffInMilliseconds % 3600000) % 60000) / 1000)

        // Pad hours, minutes, and seconds with zeros if they are less than 10
        const hoursStr = hours < 10 ? '0' + hours : hours.toString()
        const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString()
        const secondsStr = seconds < 10 ? '0' + seconds : seconds.toString()

        return `${hoursStr}:${minutesStr}:${secondsStr}`
    }

    useEffect(() => {
        if (sessionStatus?.playbackState !== 'playing') {
            setElapsedTimeValue('00:00:00')
        } else {
            intervalId.current = window.setInterval(() => {
                setElapsedTimeValue(elapsedTime(sessionStatus?.audioStartedAt ?? ''))
            }, 1000)
        }

        return () => {
            if (intervalId.current !== null) {
                clearInterval(intervalId.current)
            }
        }
    }, [sessionStatus?.audioStartedAt, sessionStatus?.playbackState])
    return (
        <TimeCodeContainer>
            <Typography color={theme?.palette?.grey?.[10]} size="md" fontFamily={'Open Sans'}>
                {elapsedTimeValue}
            </Typography>
        </TimeCodeContainer>
    )
}
