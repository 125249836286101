import {
    useIsLiveMachineActive,
    useLiveMachine,
} from 'src/state/state-machines/LiveMachine/LiveMachineProvider'

export const useIsReadOnlyMode = (): boolean => {
    let retVal = false
    const isLiveMachineActive = useIsLiveMachineActive()
    // added guarding to not invoke `useLiveMachine` if not active
    // as it will throw an error if used in normal or non-live mode
    // also added as external helper call to not violate the rules of hooks
    if (isLiveMachineActive) retVal = CheckLiveIsReadOnly()
    return retVal
}

const CheckLiveIsReadOnly = () => {
    const [
        {
            context: {
                controls: { realtimeReadOnly },
            },
        },
    ] = useLiveMachine(['controls'])
    return !!realtimeReadOnly
}
