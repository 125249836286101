import { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'src/components/styled'
import { ifProp } from 'styled-tools'
import { Spacer, Box } from '@chakra-ui/react'
import { Toolbar, IconButton, Button, ButtonGroup } from '@verbit-ai/verbit-ui-library'
import { DownloadIcon, UploadIcon } from '@verbit-ai/icons-library'
import { EndSessionModal } from './EndSessionModal'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import {
    useIsLiveMachineActive,
    useLiveMachine,
} from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { useToast, UseToastOptions } from '@verbit-ai/verbit-ui-library'
import MediaSource from './MediaSource'

const StyledToolbar = styled(Toolbar)<{ $isToolbarVisible: boolean }>`
    position: relative;
    display: ${ifProp('$isToolbarVisible', 'flex', 'none !important')};
    background-color: transparent;
    width: 100%;
    padding: 10px;
    margin-right: 22px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.visible {
        opacity: 1;
    }

    &.hidden {
        opacity: 0;
    }
`

const StyledIconButton = styled(IconButton)`
    &:active,
    &:focus {
        border: none;
    }
`

export const TaskToolbar = () => {
    const [
        {
            context: { httpClient },
        },
    ] = useAppMachine(['httpClient'])
    const isLiveMachineActive = useIsLiveMachineActive()
    const [
        {
            context: { controls, redirect_urls, mediaSource },
        },
    ] = useLiveMachine(['controls', 'redirect_urls', 'mediaSource'])
    const READ_ONLY_URL = redirect_urls?.read_only_url
    const ORDERING_URL = redirect_urls?.ordering_url
    const isReadOnly = controls?.toolbar?.link_to_read_only?.visible
    const isEndSessionVisible = controls?.toolbar?.finish_layer?.visible
    const isDownloadTranscript = controls?.toolbar?.download_transcript?.visible
    const isToolbarVisible = controls?.toolbar?.visible
    const [isVisible, setIsVisible] = useState(isToolbarVisible)
    const [isEndSession, setIsEndSession] = useState(false)
    const handleMouseEnter = () => isToolbarVisible && setIsVisible(true)
    const toastFactory = useToast()
    const [isVCRecording, setIsVCRecording] = useState(false) // state for verbit connect - is recording or not

    const args = {
        type: 'info',
        title: 'Link copied to clipboard',
    } as UseToastOptions

    // copies the Read Only URL to the clipboard
    const handleShareURL = () => {
        if (READ_ONLY_URL) {
            navigator.clipboard.writeText(String(READ_ONLY_URL))
            toastFactory(args)
        }
    }

    // redirects the user to Ordering Page
    const handleEndSession = async (value: boolean) => {
        setIsEndSession(false)

        if (value && ORDERING_URL) {
            await httpClient?.transcriptFinalize()
            window.open(String(ORDERING_URL), '_self')
        }
    }

    const disableStopButton =
        mediaSource?.toLowerCase() !== 'verbit_connect' ? false : isVCRecording

    return (
        <StyledToolbar
            className={`toolbar ${isLiveMachineActive && isVisible ? 'visible' : 'hidden'}`}
            onMouseEnter={handleMouseEnter}
            $isToolbarVisible={!!isToolbarVisible}
        >
            <Spacer />
            <ButtonGroup gap="2">
                {/* DOWNLOAD - Only appears in read-only mode */}
                {isDownloadTranscript && (
                    <Tooltip label={`Download Transcript`} placement="top" hasArrow>
                        <Box>
                            <IconButton
                                variant="light"
                                icon={<DownloadIcon color={theme?.palette?.grey?.[10]} />}
                                // onClick={handleDownloadTranscript}
                                aria-label="download-link"
                                size="lg"
                                boxShadow="none"
                            />
                        </Box>
                    </Tooltip>
                )}

                {/* READ ONLY */}
                {isReadOnly && (
                    <Tooltip label={`Copy link to live transcript`} placement="top" hasArrow>
                        <Box>
                            <StyledIconButton
                                variant="light"
                                icon={<UploadIcon color={theme?.palette?.grey?.[10]} />}
                                onClick={handleShareURL}
                                aria-label="read-only-link"
                                size="lg"
                                boxShadow="none"
                            />
                        </Box>
                    </Tooltip>
                )}

                {/* MEDIA SOURCE - VerbitConnect, Zoom or Teams*/}
                <MediaSource setIsVCRecording={setIsVCRecording} />

                {/* ORDERING */}
                {isEndSessionVisible && (
                    <Button
                        variant="gradient"
                        colorScheme="gray"
                        onClick={() => setIsEndSession(!isEndSession)}
                        size="lg"
                        boxShadow="none"
                        isDisabled={disableStopButton}
                    >
                        Stop Editing
                    </Button>
                )}
            </ButtonGroup>
            <EndSessionModal isOpen={isEndSession} onClose={handleEndSession} />
        </StyledToolbar>
    )
}
