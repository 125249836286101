import React, { useState, useCallback, useEffect } from 'react'
import { Button } from '@blueprintjs/core'
import { CaretDownIcon } from '@verbit-ai/icons-library'
import styled from 'styled-components/macro'
import { palette, prop, ifProp } from 'styled-tools'
import { Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'

import { MenuItemInterface, TranscriptionTask } from 'src/models'

import { SectionSelect } from './SectionSelect'

const LEFT_DIVIDER_OFFSET_PX = 248

const Container = styled.div<{ hasSection: boolean }>`
    display: flex;
    align-items: center;
    user-select: none;
    margin-bottom: ${ifProp('hasSection', '2px', '0')};

    & .select-control .bp4-button.bp4-minimal.bp4-disabled {
        cursor: auto;
    }

    & .bp4-button.bp4-minimal:focus {
        outline: none;
        background-color: ${palette('grey', 8)};
    }
`

const LeftDivider = styled.div<{ dividerWidth: number; visible: boolean }>`
    width: ${prop('dividerWidth')}px;
    height: 0;
    opacity: ${ifProp('visible', 1, 0)};
    border-bottom: 1.5px solid #ffffff;
    transition: opacity 0.2s ease-in-out 0s;
    margin: 0 5px 0 8px;
`

const RightDivider = styled.div<{ visible: boolean }>`
    flex-grow: 1;
    height: 0;
    opacity: ${ifProp('visible', 1, 0)};
    border-bottom: 1.5px solid ${palette('grey', 13)};
    transition: opacity 0.2s ease-in-out 0s;
`

const AddSectionButton = styled(Button)`
    color: blue;
    min-width: 20px;
    min-height: 20px;

    &.bp4-button {
        color: ${palette('grey', 8)};
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`

const RemoveButton = styled(Button)`
    display: flex;
    position: relative;
    right: -10px;

    &.bp4-button {
        background-color: transparent !important;
    }

    &.bp4-button .bp4-icon {
        color: rgb(179, 186, 197);
    }

    &.bp4-button:hover .bp4-icon {
        color: #5c7080;
    }
`

interface SectionBoxProps {
    element: Block
    editor: Editor
    section?: string
    controls: TranscriptionTask['payload']['controls']
    blockEditable: boolean
    onSectionChange: (section?: string) => void
    isCalibrationAnchorDisabled?: boolean
}

export const SectionBox = ({
    element,
    editor,
    section,
    controls,
    blockEditable,
    onSectionChange,
    isCalibrationAnchorDisabled,
}: SectionBoxProps) => {
    const [isHovering, setIsHovering] = useState(true)
    const [selectVisible, setSelectVisible] = useState(!!section)
    const [blockIndex] = ReactEditor.findPath(editor, element)

    useEffect(() => {
        setSelectVisible(!!section)
    }, [section])

    const handleSectionSelection = useCallback(
        (item: MenuItemInterface) => {
            onSectionChange(item.key)
        },
        [onSectionChange],
    )

    const handleSelectClose = useCallback(() => {
        if (!section) {
            setSelectVisible(false)
        }
    }, [section])

    const sectionSelectDisabled = !controls.legal.annotation.editable || !blockEditable
    const isActive = isHovering || selectVisible
    const shouldShowRemoveButton = section && blockEditable

    return (
        <Container hasSection={!!section} className="sectionbox">
            <LeftDivider visible={isActive} dividerWidth={LEFT_DIVIDER_OFFSET_PX} />
            <SectionSelect
                className={'add-events-' + blockIndex}
                items={controls.section.items}
                value={section}
                onChange={handleSectionSelection}
                disabled={sectionSelectDisabled || isCalibrationAnchorDisabled}
                onClose={handleSelectClose}
                placeholder={
                    blockEditable ? (
                        <AddSectionButton
                            rightIcon={<CaretDownIcon />}
                            minimal={true}
                            onMouseEnter={() => setIsHovering(true)}
                            onClick={() => {
                                setSelectVisible(true)
                            }}
                            tabIndex={-1}
                        >
                            {isActive && <span>Add event</span>}
                        </AddSectionButton>
                    ) : null
                }
            />
            <RightDivider visible={isActive} />
            {shouldShowRemoveButton && (
                <RemoveButton
                    icon="small-cross"
                    minimal
                    onClick={() => onSectionChange(undefined)}
                    tabIndex={-1}
                />
            )}
        </Container>
    )
}
