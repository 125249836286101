export const isMac = navigator.platform.toUpperCase().includes('MAC')

export const getBrowserName = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    switch (true) {
        case userAgent.includes('edge'):
            return 'MS Edge (EdgeHtml)'
        case userAgent.includes('edg'):
            return 'MS Edge Chromium'
        // @ts-ignore
        case userAgent.includes('opr') && !!window.opr:
            return 'opera'
        // @ts-ignore
        case userAgent.includes('chrome') && !!navigator.brave:
            return 'brave'
        // @ts-ignore
        case userAgent.includes('chrome') && !!window.chrome:
        case userAgent.includes('crios'):
            return 'chrome'
        case userAgent.includes('trident'):
            return 'Internet Explorer'
        case userAgent.includes('firefox'):
            return 'firefox'
        case userAgent.includes('safari'):
            return 'safari'
        case userAgent.includes('jsdom'):
            return 'jsdom'
        default:
            return 'other'
    }
}

export const isSupportedBrowser = () => {
    const browserName = getBrowserName()
    return browserName === 'chrome' || browserName === 'MS Edge Chromium' || browserName === 'jsdom'
}

function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
        return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
    }

    return 'unknown'
}

export const isMobileOperatingSystem = () => {
    return getMobileOperatingSystem() !== 'unknown'
}

export const isTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
    )
}

export const consoleClear = () => setTimeout(console.clear.bind(console))
