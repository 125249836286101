import { useState, useEffect, ReactNode, useCallback, useMemo, MutableRefObject } from 'react'

import { useAnalytics } from 'src/analytics'
import { createSubscriptionService } from 'src/factories/SubscriptionService'
import { SessionStatus } from 'src/models'
import { ErrorCode } from 'src/network'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import { useToast } from '@verbit-ai/verbit-ui-library'

const SESSION_STATUS_POLLING_IN_SECONDS = 10
const NETWORK_TOAST_ID = 'offlineNetworkStatus'

interface SessionStatusContextValue {
    sessionStatus: SessionStatus | null
    refreshSessionStatus: () => Promise<void>
}

const [SessionStatusContextProvider, useSessionStatus, useSessionStatusService] =
    createSubscriptionService<SessionStatusContextValue>({
        refreshSessionStatus: () => Promise.resolve(),
        sessionStatus: null,
    })

export { useSessionStatus, useSessionStatusService }

interface SessionStatusProviderProps {
    children: ReactNode
}

export const SessionStatusProvider = ({ children }: SessionStatusProviderProps) => {
    const [status, setStatus] = useState<SessionStatus | null>(null)
    const analytics = useAnalytics()
    const isOnline = useNetworkStatus()
    const addToast = useToast()

    const [{ context: appContext, done: appMachineDone }] = useAppMachine([
        'workerId',
        'workerName',
        'httpClient',
        'workerEmail',
        'error',
        'shouldSendStatusRequests',
    ])
    const { workerId, workerName, httpClient, error, shouldSendStatusRequests } = appContext

    const fetchSessionStatus = useCallback(
        async (isCanceledRef?: MutableRefObject<boolean>) => {
            try {
                const newStatus = await httpClient.getSessionStatus()

                if (isCanceledRef?.current) {
                    return
                }

                setStatus(newStatus)
            } catch (e) {
                console.error('/status', e)
            }
        },
        [httpClient],
    )

    useEffect(() => {
        !isOnline &&
            addToast({
                type: 'network',
                id: NETWORK_TOAST_ID,
                title: 'Network is offline',
                description:
                    'Editing is impossible at the moment due to internet connectivity issues.',
                duration: 10000,
            })
    }, [isOnline])

    useEffect(() => {
        const isCanceledRef: MutableRefObject<boolean> = { current: false }

        if (
            !workerId ||
            !workerName ||
            appMachineDone ||
            error?.code === ErrorCode.WorkerRemovedTaskTimeout ||
            !shouldSendStatusRequests
        ) {
            return
        }

        fetchSessionStatus(isCanceledRef).catch(() => {})
        const interval = setInterval(() => {
            if (!error?.message) fetchSessionStatus(isCanceledRef).catch(() => {})
        }, SESSION_STATUS_POLLING_IN_SECONDS * 1000)
        return () => {
            clearInterval(interval)
            isCanceledRef.current = true
        }
    }, [
        workerId,
        workerName,
        analytics,
        httpClient,
        fetchSessionStatus,
        appMachineDone,
        error?.code,
        error?.message,
        shouldSendStatusRequests,
    ])

    const contextValue = useMemo<SessionStatusContextValue>(
        () => ({
            sessionStatus: status,
            refreshSessionStatus: () => fetchSessionStatus(),
        }),
        [fetchSessionStatus, status],
    )

    return (
        <SessionStatusContextProvider data={contextValue}>{children}</SessionStatusContextProvider>
    )
}
