import { Machine, assign, Interpreter, send, DoneInvokeEvent, EventObject } from 'xstate'
import LogRocket from 'logrocket'

import { APIError, ErrorCode, HttpClient } from 'src/network'
import {
    getAPIEndpoint,
    getAuthToken,
    shouldUseBackdoor,
    getEnv,
    getPlatformUrl,
} from 'src/utils/env'
import {
    EditorControls,
    FeatureFlag,
    LiveTranscriptionLayerId,
    SessionStats,
    TranscriptionLayerId,
    WorkerId,
} from 'src/models'
import { toFeatureFlags, WorkerStatus, RedirectURLs } from 'src/network/responses'
import { isMobileOperatingSystem, isSupportedBrowser, isTablet } from 'src/utils/platform'
import {
    analytics,
    USER_ID_TITLE,
    TRAX_USER_ROLES_TITLE,
    LOG_ROCKET_SESSION_URL,
} from 'src/analytics'
import { TaskCache } from 'src/state/TaskCache'
import { appcues } from 'src/appcues'
import { TaskMachine } from '../TaskMachine/TaskMachine'
import { LiveMachine } from '../LiveMachine'

const RETRY_SESSION_INTERVAL_MS = 30000
let intervalID: any = undefined

export interface AppStateSchema {
    states: {
        'join-attempt': {}
        'rejected-join-attempt': {}
        working: {}
        'working-live': {}
        processing: {}
        'loading-stats': {}
        stats: {}
        error: {}
        'unsupported-browser': {}
        'backdoor-join-screen': {}
    }
}

export interface AppStateContext {
    httpClient: HttpClient
    authToken?: string
    sessionId?: string
    workerId?: WorkerId
    workerName?: string
    workerEmail?: string
    layerIds?: TranscriptionLayerId[]
    featureFlags?: { [featureName: string]: FeatureFlag }
    mediaSource?: string
    redirect_urls?: RedirectURLs
    strategyName?: string
    error?: APIError
    sessionStats?: SessionStats
    shouldSendStatusRequests: boolean
    platformWorkerId?: number
    isEditorInGlossersMode?: boolean
    metadata?: { fps: number }
    websocketUrl?: string
    controls?: EditorControls
}

interface ErrorEvent extends EventObject {
    type: 'ERROR'
    error: APIError
}

type FinishTaskData =
    | { type: 'user-exit' }
    | { type: 'finished' }
    | { type: 'error'; error: APIError }

interface FinishTaskEvent extends DoneInvokeEvent<FinishTaskData> {
    type: 'done.invoke.worker'
}

interface JoinApprovedEvent extends EventObject {
    type: 'JOIN_APPROVED'
    sessionId: string
    workerId: string
    workerName: string
    workerEmail: string
    layerIds: TranscriptionLayerId[]
    platformWorkerId: number | undefined
    featureFlags?: { [featureName: string]: FeatureFlag }
    strategyName?: string
    metadata?: { fps: number }
}

interface JoinApprovedLiveEvent extends EventObject {
    type: 'JOIN_APPROVED_LIVE'
    sessionId: string
    workerId: string
    workerName: string
    workerEmail: string
    layerIds: TranscriptionLayerId[]
    platformWorkerId: number | undefined
    featureFlags?: { [featureName: string]: FeatureFlag }
    mediaSource?: string
    redirect_urls?: RedirectURLs
    strategyName?: string
    metadata?: { fps: number }
    websocketUrl?: string
    controls?: EditorControls
}

interface JoinRejectedEvent extends EventObject {
    type: 'JOIN_REJECTED'
    error: APIError
}

export type AppEvents =
    | ErrorEvent
    | JoinApprovedEvent
    | JoinApprovedLiveEvent
    | JoinRejectedEvent
    | { type: 'RETRY' }
    | { type: 'SESSION_FINISHED' }
    | FinishTaskEvent
    | { type: 'STATS_LOADED'; sessionStats: SessionStats }
    | { type: 'JOIN_WITH_BACKDOOR'; workerName: string; layerIds: LiveTranscriptionLayerId[] }
    | { type: 'TURN_OFF_STATUS_REQUESTS' }
    | { type: 'TURN_ON_STATUS_REQUESTS' }
    | { type: 'TURN_ON_IS_EDITOR_IN_GLOSSERS_MODE' }

export type AppMachine = Interpreter<AppStateContext, AppStateSchema, AppEvents>

function getInitialState(): keyof AppStateSchema['states'] {
    if (!isSupportedBrowser() || (isMobileOperatingSystem() && !isTablet())) {
        return 'unsupported-browser'
    }

    if (shouldUseBackdoor()) {
        return 'backdoor-join-screen'
    }

    return 'join-attempt'
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppMachine = Machine<AppStateContext, AppStateSchema, AppEvents>(
    {
        id: 'trax',
        initial: getInitialState(),
        context: {
            httpClient: new HttpClient({ baseUrl: getAPIEndpoint() }),
            shouldSendStatusRequests: true,
            isEditorInGlossersMode: false, // TODO:: Temporary for determine if we should send the appcues event in the Stats page
        },
        states: {
            'backdoor-join-screen': {
                on: {
                    JOIN_WITH_BACKDOOR: 'join-attempt',
                    ERROR: 'error',
                },
            },
            'join-attempt': {
                on: {
                    JOIN_APPROVED: {
                        target: 'working',
                        actions: [
                            assign({
                                workerId: (context, event) => event.workerId,
                                sessionId: (context, event) => event.sessionId,
                                workerName: (context, event) => event.workerName,
                                workerEmail: (context, event) => event.workerEmail,
                                layerIds: (context, event) => event.layerIds,
                                featureFlags: (context, event) => event.featureFlags,
                                strategyName: (context, event) => event.strategyName,
                                platformWorkerId: (context, event) => event.platformWorkerId,
                                metadata: (context, event) => event.metadata,
                            }),
                            'initializeAnalytics',
                        ],
                    },
                    JOIN_APPROVED_LIVE: {
                        target: 'working-live',
                        actions: [
                            assign({
                                workerId: (context, event) => event.workerId,
                                sessionId: (context, event) => event.sessionId,
                                workerName: (context, event) => event.workerName,
                                workerEmail: (context, event) => event.workerEmail,
                                layerIds: (context, event) => event.layerIds,
                                featureFlags: (context, event) => event.featureFlags,
                                mediaSource: (context, event) => event.mediaSource,
                                redirect_urls: (context, event) => event.redirect_urls,
                                strategyName: (context, event) => event.strategyName,
                                platformWorkerId: (context, event) => event.platformWorkerId,
                                metadata: (context, event) => event.metadata,
                                websocketUrl: (context, event) => event.websocketUrl,
                                controls: (_, event) => event.controls,
                            }),
                            'initializeAnalytics',
                        ],
                    },
                    JOIN_REJECTED: 'rejected-join-attempt',
                    ERROR: 'error',
                },
                invoke: {
                    id: 'joinSession',
                    src: 'joinSession',
                    onError: {
                        actions: send({ type: 'ERROR', error: (_: any, e: any) => e.data.error }),
                    },
                },
            },
            'working-live': {
                invoke: {
                    id: 'live-machine',
                    src: LiveMachine,
                    data: {
                        httpClient: (ctx: AppStateContext) => ctx.httpClient,
                        websocketUrl: (ctx: AppStateContext) => ctx.websocketUrl,
                        controls: (ctx: AppStateContext) => ctx.controls,
                        sessionId: (ctx: AppStateContext) => ctx.sessionId,
                        mediaSource: (ctx: AppStateContext) => ctx.mediaSource,
                        redirect_urls: (ctx: AppStateContext) => ctx.redirect_urls,
                    },
                },
            },
            working: {
                on: {
                    TURN_ON_STATUS_REQUESTS: {
                        actions: assign({ shouldSendStatusRequests: (_) => true }),
                    },
                    TURN_OFF_STATUS_REQUESTS: {
                        actions: assign({ shouldSendStatusRequests: (_) => false }),
                    },
                    TURN_ON_IS_EDITOR_IN_GLOSSERS_MODE: {
                        actions: assign({
                            isEditorInGlossersMode: (ctx) => true,
                        }),
                    },
                },
                entry: assign({
                    shouldSendStatusRequests: (ctx) => true,
                    error: (ctx) => undefined,
                }),
                invoke: {
                    id: 'worker',
                    src: TaskMachine,
                    onDone: 'processing',
                    data: {
                        httpClient: (ctx: AppStateContext) => ctx.httpClient,
                        workerId: (ctx: AppStateContext) => ctx.workerId,
                    },
                },
            },
            processing: {
                on: {
                    SESSION_FINISHED: 'loading-stats',
                    ERROR: 'error',
                },
                invoke: {
                    id: 'processing',
                    src: 'processing',
                },
            },
            'loading-stats': {
                on: {
                    STATS_LOADED: {
                        target: 'stats',
                        actions: assign({ sessionStats: (_, e) => e.sessionStats }),
                    },
                    ERROR: 'error',
                },
                invoke: {
                    id: 'loadStats',
                    src: 'loadStats',
                },
            },
            'rejected-join-attempt': {
                entry: assign({
                    error: (ctx, e) => (e.type === 'JOIN_REJECTED' ? e.error : undefined),
                    shouldSendStatusRequests: (_) => false,
                }),
                on: {
                    RETRY: 'join-attempt',
                },
                invoke: {
                    id: 'retrySession',
                    src: 'retrySession',
                },
            },
            stats: {
                type: 'final',
            },
            error: {
                entry: assign({
                    error: (ctx, e) => (e.type === 'ERROR' ? e.error : undefined),
                    shouldSendStatusRequests: (_) => false,
                }),
                on: {
                    RETRY: 'working',
                },
            },
            'unsupported-browser': {
                type: 'final',
            },
        },
    },
    {
        services: {
            joinSession: (ctx, evt) => async (send) => {
                try {
                    // Replace the base url if needed (query the server wether to work with stateless/full)
                    // If there is any error, continue with the current base url.
                    try {
                        const env = getEnv()
                        const authToken = getAuthToken()

                        if (env !== 'dev' && authToken) {
                            const response = await fetch(`/api/v1/route?auth_token=${authToken}`)

                            if (response.ok) {
                                const json = await response.json()

                                if (json.route) {
                                    ctx.httpClient.updateBaseUrl(json.route)
                                }
                            } else {
                                switch (response.status) {
                                    case 431: {
                                        const url = new URL(response.url)
                                        window.Rollbar?.error(
                                            `GET ${url.origin}${url.pathname}: Error 431 Request Header Fields Too Large (Likely due to auth token)`,
                                        )
                                        break
                                    }
                                    case 401: {
                                        window.location.assign(
                                            `${getPlatformUrl()}/users/sign_in?redirect_to=${encodeURIComponent(
                                                window.location.href,
                                            )}`,
                                        )
                                        break
                                    }
                                    default: {
                                        console.log('Failed to fetch base url', response)
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        console.log('Failed to fetch base url', e)
                    }

                    let workerStatus: WorkerStatus
                    if (evt.type === 'JOIN_WITH_BACKDOOR') {
                        workerStatus = await ctx.httpClient.joinSessionWithUserDetails(
                            evt.workerName,
                            evt.layerIds,
                        )
                        ctx.httpClient.setAuthToken(workerStatus.token)
                    } else {
                        const authToken = getAuthToken()
                        workerStatus = await ctx.httpClient.joinSessionWithToken(authToken)
                    }

                    // This is required! Otherwise, some API calls that require the workerId won't work
                    ctx.httpClient.setWorkerId(workerStatus.id)

                    const status = await ctx.httpClient.getSessionStatus()

                    try {
                        LogRocket.track('JOIN_SESSION', {
                            sessionId: status.sessionId,
                            authToken: getAuthToken() || '',
                        })
                        LogRocket.info(`Joined session: ${status.sessionId}`)

                        if (!TaskCache.verifyCache(status.sessionId, workerStatus.id)) {
                            TaskCache.clearCache()
                        }
                        // save session anyways so we can detect duplicate tabs
                        TaskCache.saveSession(status.sessionId, workerStatus.id)
                    } catch (e) {
                        TaskCache.clearCache()
                    }

                    const urlParams = new URLSearchParams(window.location.search)

                    const isLiveMode = Boolean(workerStatus.sapirChannelId) || urlParams.has('live')

                    send({
                        type: isLiveMode ? 'JOIN_APPROVED_LIVE' : 'JOIN_APPROVED',
                        sessionId: status.sessionId,
                        workerId: workerStatus.id,
                        workerName: workerStatus.displayName,
                        workerEmail: workerStatus.email,
                        layerIds: workerStatus.permittedLayers,
                        featureFlags:
                            workerStatus.featureFlags && toFeatureFlags(workerStatus.featureFlags),
                        mediaSource: workerStatus.mediaSource,
                        redirect_urls: workerStatus.redirect_urls,
                        strategyName: workerStatus.strategy,
                        platformWorkerId: workerStatus.userId,
                        metadata: workerStatus.metadata,
                        controls: workerStatus.controls,
                        websocketUrl: workerStatus.sapirConnectionUrl,
                    })
                    if (intervalID) {
                        clearInterval(intervalID)
                    }
                    return
                } catch (e) {
                    if (e instanceof APIError) {
                        switch (e.code) {
                            case ErrorCode.UnauthorizedUser: {
                                window.location.assign(
                                    `${getPlatformUrl()}/users/sign_in?redirect_to=${encodeURIComponent(
                                        window.location.href,
                                    )}`,
                                )
                                break
                            }
                            default: {
                                send({ type: 'JOIN_REJECTED', error: e })
                            }
                        }
                        return
                    }

                    send({ type: 'ERROR', error: e as APIError })
                    return
                }
            },
            retrySession: () => async (send) => {
                try {
                    intervalID = setInterval(() => {
                        send({ type: 'RETRY' })
                    }, RETRY_SESSION_INTERVAL_MS)
                } catch (e) {
                    send({ type: 'ERROR', error: e as APIError })
                }
            },
            processing: (ctx, e) => async (send) => {
                const { httpClient } = ctx
                if (e.type !== 'done.invoke.worker') {
                    throw new Error(
                        `unknown event type when trying to finish session: ${JSON.stringify(e)}`,
                    )
                }

                if (e.data.type === 'user-exit') {
                    try {
                        await httpClient.exitSession()
                    } catch (e) {
                        send({ type: 'ERROR', error: e as APIError })
                        return
                    }
                }

                if (['user-exit', 'finished', 'timeout', 'removed-by-ops'].includes(e.data.type)) {
                    send('SESSION_FINISHED')
                } else if (e.data.type === 'error') {
                    send({ type: 'ERROR', error: e.data.error })
                }
            },
            loadStats: (context) => async (send) => {
                try {
                    const sessionStats = await context.httpClient.getSessionStats()
                    send({ type: 'STATS_LOADED', sessionStats })
                } catch (e) {
                    console.log('load stats error', e)
                    send({ type: 'ERROR', error: e as APIError })
                }
            },
        },
        actions: {
            // TODO: try to unite with all the other service libs
            initializeAnalytics: (context) => {
                console.log('initializing analytics ...')

                console.log(
                    `platformWorkerId ${!!context.platformWorkerId ? 'exists' : "doesn't exist"}`,
                )

                if (!!context.platformWorkerId) {
                    const platformWorkerIdString = String(context.platformWorkerId)

                    analytics?.mixPanelIdentify(platformWorkerIdString)
                    appcues?.identify(platformWorkerIdString, context.workerName, context.layerIds)
                }

                LogRocket.getSessionURL((logRocketSessionURL) => {
                    analytics.extendClientUserProps({
                        [USER_ID_TITLE]: context.workerId,
                        [TRAX_USER_ROLES_TITLE]: context.layerIds,
                    })
                    // CR => send the join session analytics call only when we actually have the session id in src/state/session.tsx

                    window.Rollbar?.configure({
                        payload: {
                            person: {
                                id: context.workerName!,
                                username: context.workerName!,
                            },
                        },
                        transform: (errorData: any) => {
                            errorData[LOG_ROCKET_SESSION_URL] = logRocketSessionURL
                            return errorData
                        },
                    })

                    if (!context.platformWorkerId) {
                        window.Rollbar?.error(
                            `platformWorkerId doesn't exist while trying to initialize analytics. There won't be any events sent to mixpanel!`,
                        )
                    }
                })
            },
        },
    },
)
