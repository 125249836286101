import { useState, useEffect } from 'react'

/**
 * custom hook to listen network connection status
 */
export const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true)
        }

        const handleOffline = () => {
            setIsOnline(false)
        }

        // Add event listeners for online and offline events
        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    return isOnline
}
