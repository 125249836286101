import React, { useState, useCallback } from 'react'
import { Button } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Header } from 'src/components/Session/panels/SpeakerPanel/common'
import { SearchIcon } from '@verbit-ai/icons-library'
import { Input } from '@verbit-ai/verbit-ui-library'
import { EventsRow } from './EventsRow'
import { useEventsMarking } from 'src/state/EventsProvider'

const EventsPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-bottom: none;
    background-color: ${palette('white', 0)};
    overflow-y: hidden;
`

const EventsPanelHeader = styled(Header)`
    padding: 0 16px;
    border-bottom: solid 1px ${palette('cloudBlueLight', 1)};
`

const EventsList = styled.div`
    overflow-y: auto;
`

const NoEventsFound = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${palette('grey', 8)};
    margin-top: 10px;
`

const StyledButton = styled(Button)`
    background-color: transparent !important;

    &.bp4-button .bp4-icon {
        color: rgb(179, 186, 197);
    }

    &.bp4-button:hover .bp4-icon {
        color: #5c7080;
    }
`

export const EventsPanel = () => {
    const [isShowSearchBox, setIsShowSearchBox] = useState(false)
    const { searchValue, setSearchValue, eventsList } = useEventsMarking()

    const handleSearchEvent = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue && setSearchValue(e.target.value)
        },
        [setSearchValue],
    )

    const handleSearchButtonClose = useCallback(() => {
        setSearchValue && setSearchValue('')
        setIsShowSearchBox(false)
    }, [setSearchValue])

    const handleSearchEventKeydown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Escape') {
                e.preventDefault()
                e.stopPropagation()
                handleSearchButtonClose()
            }
        },
        [handleSearchButtonClose],
    )

    return (
        <EventsPanelContainer>
            <EventsPanelHeader>
                {!isShowSearchBox && <span>Event List</span>}
                {isShowSearchBox && (
                    <Input
                        type="text"
                        value={searchValue}
                        isFullWidth
                        placeholder="Search..."
                        size="lg"
                        rightIcon={<SearchIcon />}
                        tabIndex={0}
                        maxLength={50}
                        autoFocus
                        onChange={handleSearchEvent}
                        onKeyDown={handleSearchEventKeydown}
                    />
                )}

                <StyledButton
                    icon={!isShowSearchBox ? 'search' : 'cross'}
                    minimal
                    onClick={() => setIsShowSearchBox(!isShowSearchBox)}
                />
            </EventsPanelHeader>
            <EventsList>
                <EventsRow />
                {eventsList.length === 0 && <NoEventsFound>No events found</NoEventsFound>}
            </EventsList>
        </EventsPanelContainer>
    )
}
