import { useMemo, useRef } from 'react'

import { useIsEditorEmpty } from 'src/components/Editor/plugins/withTranscript/hooks/useIsEditorEmpty'
import { createSubscriptionService } from 'src/factories/SubscriptionService'

import { ScrollingWrapper } from './styles'
import { useAutoScroll } from './useAutoScroll'
import { ScrollToBottomButton } from './ScrollToBottomButton'
import React from 'react'

type AutoScrollContextProps = {
    autoScrollRef: React.RefObject<HTMLDivElement>
    isAutoScrollMode: boolean
    hasUnreadContents: boolean
    enterAutoScroll: () => void
    exitAutoScroll: () => void
}

const [Provider, useAutoScrollContext, useAutoScrollContextService] =
    createSubscriptionService<AutoScrollContextProps>({
        autoScrollRef: { current: null },
        isAutoScrollMode: false,
        hasUnreadContents: false,
        enterAutoScroll: () => {},
        exitAutoScroll: () => {},
    })

export { useAutoScrollContext, useAutoScrollContextService }

type AutoScrollContainerProps = {
    children: React.ReactNode
}

/**
 * Renders a div that acts as a scroll-container for all of its children and
 * provides auto-scroll behaviour for the rendered scroll-container.
 * It also exposes an API to control the auto-scroll behaviour via a react context.
 *
 * auto-scroll behaviour includes:
 * - automatic scroll-to-bottom when in auto-scroll mode and new websocket messages are received
 * - automatically engage auto-scroll mode when scrolling to the bottom of the container
 * - automatically disengage auto-scroll mode when scrolling up
 * - "scroll-to-bottom"-Button when not in auto-scroll mode
 * - unread-contents-indicator when not in auto-scroll mode
 */
export function AutoScrollContainer({ children }: AutoScrollContainerProps) {
    const scrollingWrapperRef = useRef(null)
    const isRealTimeReadOnly = false // useIsReadOnlyMode()
    const isEditorEmpty = useIsEditorEmpty()

    const { enterAutoScroll, exitAutoScroll, hasUnreadContents, isAutoScrollMode } = useAutoScroll(
        scrollingWrapperRef,
        { autoScrollTriggerThresholdPx: 100 },
    )

    return (
        <>
            <ScrollingWrapper
                ref={scrollingWrapperRef}
                isEditorEmpty={isEditorEmpty}
                $isRealTimeReadOnly={isRealTimeReadOnly}
                tabIndex={-1}
                onKeyDown={() => {
                    exitAutoScroll()
                }}
                id={'scrollingWrapper'}
            >
                <Provider
                    data={useMemo(
                        () => ({
                            autoScrollRef: scrollingWrapperRef,
                            isAutoScrollMode,
                            hasUnreadContents,
                            enterAutoScroll,
                            exitAutoScroll,
                        }),
                        [enterAutoScroll, exitAutoScroll, hasUnreadContents, isAutoScrollMode],
                    )}
                >
                    {children}
                </Provider>
            </ScrollingWrapper>
            {!isAutoScrollMode && (
                <ScrollToBottomButton
                    hasUnreadMessages={hasUnreadContents}
                    onClick={enterAutoScroll}
                />
            )}
        </>
    )
}
