import { useEffect, useMemo, useRef, useState } from 'react'
import { Editor, RangeRef } from 'slate'
import { useSlateStatic } from 'slate-react'
import { EditorContext, useEditorMeta, useEditorPopup } from 'src/components/Editor/EditorContext'
import { withSuggestions } from 'src/components/Editor/plugins/withSuggestions/withSuggestions'
import { withTags } from 'src/components/Editor/plugins/withTags/withTags'
import { Timeline } from 'src/components/Editor/plugins/withTimeline/timeline'
import { withTimeline } from 'src/components/Editor/plugins/withTimeline/withTimeline'
import { withLiveEdits } from 'src/components/Editor/plugins/withLiveEdits/withLiveEdits'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useLiveMachine } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { useSpeakers } from 'src/state/SpeakersProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'
import { createBlocks, withTranscript } from 'src/components/Editor/plugins/withTranscript'
import { useCurrentTimeRef } from 'src/components/Editor/plugins/withTimeline/hooks/useCurrentTimeRef'
import { TextEditor } from 'src/components/Editor/TextEditor'
import { useTagsContextMenu } from 'src/components/Editor/plugins/withTags/useTagsContextMenu'
import { useSpellingContextMenu } from 'src/components/Editor/plugins/withSpellchecker/useSpellingContextMenu'
import { useSuggestionsContextMenu } from 'src/components/Editor/plugins/withSuggestions/useSuggestionsContextMenu'
import { useEditorUnclears } from 'src/components/Editor/plugins/withTags/useEditorUnclears'
import useEditorSpeaker from 'src/components/Editor/plugins/withTranscript/hooks/useEditorSpeaker'
import useEditorExhibits from 'src/components/Editor/plugins/withTranscript/hooks/useEditorExhibits'
import useEditorTabNavigation from 'src/components/Editor/plugins/withTranscript/hooks/useEditorTabNavigation'
import useEditorEvents from 'src/components/Editor/plugins/withTranscript/hooks/useEditorEvents'
import { useIsEditorEmpty } from 'src/components/Editor/plugins/withTranscript/hooks/useIsEditorEmpty'
import { FadedBottomEdge, Page } from './styles'
import { useLiveEditor } from './useLiveEditor'
import { LIVE_EDITS_SUBMIT_DEBOUNCE_TIME_MS } from './consts'
import { WelcomeSession } from '../SessionScreens'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { withLogger } from 'src/components/Editor/plugins/withLogger'
import { useEditorAudioSync } from 'src/components/Editor/plugins/withTimeline/hooks/useEditorAudioSync'
import { useSessionMediaCurrentTime } from 'src/state/SessionMediaProvider'
import { withTimecode } from 'src/components/Editor/plugins/withTimeCode/withTimeCode'
import { HeuristicAligner } from 'src/components/Editor/plugins/withTimeline/align/HeuristicAligner'
import { isMac } from 'src/utils/platform'
import { useLiveCriticalIssueHandler } from '../useLiveCriticalIssueHandler/useLiveCriticalIssueHandler'
import { AutoScrollContainer } from './AutoScrollContainer'
import { useIsReadOnlyMode } from '../../useIsReadOnlyMode'

export function LiveEditor() {
    const { sessionStatus } = useSessionStatus(['sessionStatus.sessionId'])
    const [{ context }] = useLiveMachine(['liveTranscript', 'controls', 'sessionId'])
    const { liveTranscript, controls } = context
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')

    const [[initialContent, timeline]] = useState(() => {
        const [editable, timings] = createBlocks(liveTranscript!, {
            editable: liveTranscript!.editable,
            isFirstSegment: true,
        })

        const timeline = new Timeline({
            segmentTimings: liveTranscript!.timing,
            aligner: new HeuristicAligner(),
            initialSegment: timings,
        })

        return [editable, timeline] as const
    })
    const currentTimeRef = useCurrentTimeRef()

    const lastWebsocketRangeRef = useRef<RangeRef | null>(null)
    const onError = useLiveCriticalIssueHandler()

    const plugins = useMemo(() => {
        let plugins = [
            withTranscript({
                isSpeakerLineVisible: controls.diarization.visible,
                shouldEnableQACAutomation: false,
            }),
            withTags({
                enableGlossary: true,
                enableUnclears: true,
                enableLabels: controls.label.visible,
                labels: controls.label.items,
            }),
            withTimeline({ timeline, currentTimeRef, onError }),
            withLogger,
            // withSpellchecker(spellchecker), // IdanH: Commented out as an optimization
            withLiveEdits({
                onSubmit: (payload) => context.httpClient!.postTranscript(payload),
                onError,
                enableSubmit: !isRealTimeReadOnly,
                controls,
                debounceTime: LIVE_EDITS_SUBMIT_DEBOUNCE_TIME_MS,
                lastWebsocketRangeRef,
                eventsMarkingFlag,
            }),
            withTimecode,
        ]
        const shouldAddSuggestionsPlugin = controls.suggestionsEnabled

        if (shouldAddSuggestionsPlugin) {
            plugins.push(withSuggestions)
        }

        return plugins
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline])

    return (
        <EditorContext
            plugins={plugins}
            content={initialContent}
            onChange={() => {}}
            meta={useMemo(
                () => ({
                    controls,
                    editorMode: 'basic',
                    taskId: sessionStatus?.sessionId,
                    sessionId: sessionStatus?.sessionId,
                }),
                [controls, sessionStatus?.sessionId],
            )}
        >
            <InnerEditor lastWebsocketRangeRef={lastWebsocketRangeRef} />
        </EditorContext>
    )
}

type InnerEditorProps = {
    lastWebsocketRangeRef: React.MutableRefObject<RangeRef | null>
}

function InnerEditor({ lastWebsocketRangeRef }: InnerEditorProps) {
    const editor = useSlateStatic()
    const editorRef = useRef<HTMLDivElement>(null)

    const isEditorEmpty = useIsEditorEmpty()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const { sessionStatus } = useSessionStatus()
    const { setPendingSpeaker } = useSpeakers(['setPendingSpeaker'])
    useTagsContextMenu()
    useSpellingContextMenu()
    useSuggestionsContextMenu()
    useEditorUnclears()
    useEditorSpeaker()
    useEditorAudioSync(editorRef)
    useSessionMediaCurrentTime() // use it as an update-subscription to currentTime
    useEditorExhibits()
    useEditorTabNavigation()
    useEditorEvents()

    const { openPopup } = useEditorPopup()

    const handleAddSpeaker = (e: KeyboardEvent) => {
        if (isRealTimeReadOnly) return
        setPendingSpeaker({
            pendingSpeaker: { name: '', role: '', samples: {} },
            onCreate: () => {},
        })
    }

    const propagateObject = {
        preventDefault: true,
        stopPropagation: true,
        stopImmediatePropagation: true,
    }

    // Open Add speaker form
    useMousetrap(['mod+shift+a'], handleAddSpeaker, {
        preventDefault: true,
        stopImmediatePropagation: true,
    })

    useMousetrap(isMac ? 'alt+g' : 'ctrl+g', () => openPopup('glossary'), {
        label: 'Open Glossary',
        ...propagateObject,
    })

    useMousetrap(isMac ? 'alt+d' : 'ctrl+d', () => openPopup('context'), {
        label: 'Open Context Menu',
        ...propagateObject,
    })

    const { controls } = useEditorMeta()
    useLiveEditor({
        allowedEventTypes: controls.realtimeReadOnly ? ['new', 'update'] : ['new'],
        lastWebsocketRangeRef,
        lastUpdateHighlightTimeoutMs: 1_000,
        allowLegalAnnotationAutomation: false, // !isRealTimeReadOnly, // changed to false as per IdanH requirement for RND-42176
    })

    useEffect(() => {
        Editor.normalize(editor, { force: true })
    }, [editor])

    const isInitialSession =
        isEditorEmpty &&
        sessionStatus?.playbackState &&
        sessionStatus?.playbackState.toLowerCase() !== 'playing'

    return (
        <FadedBottomEdge>
            {isInitialSession ? (
                <WelcomeSession />
            ) : (
                <AutoScrollContainer>
                    <Page $isRealTimeReadOnly={isRealTimeReadOnly} tabIndex={-1}>
                        <TextEditor editorRef={editorRef} />
                    </Page>
                </AutoScrollContainer>
            )}
        </FadedBottomEdge>
    )
}
