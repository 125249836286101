import React, { useRef } from 'react'
import { useOutsideClick } from '@chakra-ui/react'
import { Menu as BaseMenu, OptionType, Button } from '@verbit-ai/verbit-ui-library'
import { MenuProps } from 'react-select/dist/declarations/src/components/Menu'
import { AddIcon } from '@verbit-ai/icons-library'
import styled from 'styled-components/macro'

const CUSTOM_EVENT_VALUE = '( Add custom event )'

type AddCustomEventsProps = MenuProps<OptionType> & {
    onSectionChange: (section: string) => void
}

const StyledStickyMenu = styled.div`
    position: sticky;
    bottom: 0;
    background-color: white;
    spacing: 6;
    padding: 5px;
`

// Adds a sticky menu item to the bottom of the dropdown list
const AddCustomEvents: React.FC<AddCustomEventsProps> = ({
    children,
    innerProps,
    onSectionChange,
    ...props
}) => {
    const menuRef = useRef(null)

    useOutsideClick({
        ref: menuRef,
        handler: () => props.selectProps.onMenuClose(),
    })

    const handleAddCustomEvent = () => {
        props.selectProps.onMenuClose()
        onSectionChange(CUSTOM_EVENT_VALUE)
    }

    return (
        <BaseMenu innerProps={{ ...innerProps, ref: menuRef }} {...props}>
            {children}
            <StyledStickyMenu>
                <Button
                    variant="link"
                    leftIcon={<AddIcon />}
                    onClick={handleAddCustomEvent}
                    style={{ textDecoration: 'none' }}
                >
                    Add custom event
                </Button>
            </StyledStickyMenu>
        </BaseMenu>
    )
}

export default AddCustomEvents
