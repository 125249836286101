import { Editor, Operation, Node, Path, Point, Text, NodeEntry } from 'slate'
import {
    ZERO_WIDTH_WHITESPACE,
    countWords,
    countWordsUntilOffset,
    getWordBounds,
    isWhitespace,
    splitToWords,
} from 'src/utils/string'
import { Block, ContentText } from 'src/components/Editor/plugins/withTranscript'

type TimlineSplitBlockOperation = {
    type: 'split_block'
    /** index of existing block */
    blockIndex: number
    /** index of first word in new block */
    wordIndex: number
}

type TimelineMergeBlockOperation = {
    type: 'merge_block'
    /** will be merged into previous block */
    blockIndex: number
}

type TimelineAddWordOperation = {
    type: 'add_word'
    blockIndex: number
    wordIndex: number
    wordContent: string
}

type TimelineRemoveWordOperation = {
    type: 'remove_word'
    blockIndex: number
    wordIndex: number
}

type TimelineChangeWordContentOperation = {
    type: 'change_word_content'
    blockIndex: number
    wordIndex: number
    newWordContent: string
}

export type TimelineOperation =
    | TimlineSplitBlockOperation
    | TimelineMergeBlockOperation
    | TimelineAddWordOperation
    | TimelineRemoveWordOperation
    | TimelineChangeWordContentOperation

/**
 * Translates a slate operation to a series of operations that are easier to apply to the timeline.
 */
export function translateSlateOperation(editor: Editor, operation: Operation): TimelineOperation[] {
    const ops: TimelineOperation[] = []

    switch (operation.type) {
        case 'insert_text': {
            const { path, offset, text } = operation
            const [blockIndex] = path
            const blockContents = Editor.string(editor, [blockIndex])
            const { wordIndex, blockTextOffset } = getWordInfo(editor, blockIndex, path, offset)

            // the anchor is basically the "insert point"
            const anchorPoint: Point = { path, offset }

            let beforeAnchorPoint = getBeforeAnchor(editor, anchorPoint)
            let afterAnchorPoint = getAfterAnchor(editor, anchorPoint)

            // word bounds of the word that resides at the anchor point, if null, there's no word at the anchor point
            const anchorWordBounds = getWordBounds(blockContents, blockTextOffset)

            const isCharBeforeAnchorWhitespace = beforeAnchorPoint
                ? isWhitespace(
                      Editor.string(editor, {
                          anchor: beforeAnchorPoint,
                          focus: anchorPoint,
                      }),
                  )
                : true

            const isCharAfterAnchorWhitespace = afterAnchorPoint
                ? isWhitespace(
                      Editor.string(editor, { anchor: anchorPoint, focus: afterAnchorPoint }),
                  )
                : true

            const anchorWordLeftHalf = anchorWordBounds
                ? blockContents.slice(anchorWordBounds.start, blockTextOffset)
                : ''
            const anchorWordRightHalf = anchorWordBounds
                ? blockContents.slice(blockTextOffset, anchorWordBounds.end)
                : ''

            const isFirstCharOfInsertTextWhitespace = isWhitespace(text[0])
            const isLastCharOfInsertTextWhitespace = isWhitespace(text[text.length - 1])

            const insertWords = text
                .replaceAll(ZERO_WIDTH_WHITESPACE, ' ')
                .split(/\s+/)
                .filter(Boolean)

            let anchorWordWasSplit = false

            // if there's a word at the start anchor and it's not the edge of the word
            if (!isCharBeforeAnchorWhitespace && anchorWordBounds) {
                // if we insert a space in the middle of the start anchor word
                // we should change the word to the left half of the cut-off
                if (
                    isFirstCharOfInsertTextWhitespace &&
                    anchorWordBounds.end > blockTextOffset &&
                    anchorWordLeftHalf
                ) {
                    ops.push({
                        type: 'change_word_content',
                        blockIndex,
                        wordIndex,
                        newWordContent: anchorWordLeftHalf,
                    })
                    anchorWordWasSplit = true
                }

                // if we're inserting something in the middle or end of the start anchor word
                if (!isFirstCharOfInsertTextWhitespace && anchorWordBounds.end >= blockTextOffset) {
                    let newWordContent = anchorWordLeftHalf + insertWords[0]

                    let shouldExitEarly = false

                    // if there's no space at the end and it's only one insert word
                    // we should also add the right half of the anchor word because something was added in the middle
                    if (
                        !isCharAfterAnchorWhitespace &&
                        !isLastCharOfInsertTextWhitespace &&
                        insertWords.length === 1
                    ) {
                        newWordContent += anchorWordRightHalf

                        // exit early because only one word was changed
                        shouldExitEarly = true
                    }

                    ops.push({ type: 'change_word_content', blockIndex, wordIndex, newWordContent })
                    insertWords.shift()

                    if (shouldExitEarly) {
                        break
                    }

                    if (
                        anchorWordBounds.end > blockTextOffset &&
                        isLastCharOfInsertTextWhitespace
                    ) {
                        anchorWordWasSplit = true
                    }
                }
            }

            let lastInsertWord: string | null = null
            // if both the char after the anchor and the last char of the insert text are not whitespace
            // we need to handle the last insert word differently, so we pop it and save it
            if (!isCharAfterAnchorWhitespace && !isLastCharOfInsertTextWhitespace) {
                lastInsertWord = insertWords.pop() ?? null
            }

            let lastWordIndex = wordIndex
            for (const word of insertWords) {
                lastWordIndex += 1
                ops.push({
                    type: 'add_word',
                    blockIndex,
                    wordIndex: lastWordIndex,
                    wordContent: word,
                })
            }

            // if there's a word at the end anchor and it's not the edge of the word
            if (!isCharAfterAnchorWhitespace && anchorWordBounds) {
                // if the anchor word was split, the right half of the word needs to be added as its own word
                if (anchorWordWasSplit) {
                    // if the last char of the insert text is a whitespace, we only need to add the right half of the anchor word
                    // as a new word. If it's not, we need to add the last insert word + the right half of the anchor word
                    const wordContent = isLastCharOfInsertTextWhitespace
                        ? anchorWordRightHalf
                        : lastInsertWord + anchorWordRightHalf

                    ops.push({
                        type: 'add_word',
                        blockIndex,
                        wordIndex: lastWordIndex + 1,
                        wordContent,
                    })
                } else if (lastInsertWord !== null) {
                    ops.push({
                        type: 'change_word_content',
                        blockIndex,
                        wordIndex: lastWordIndex,
                        newWordContent: lastInsertWord + anchorWordRightHalf,
                    })
                }
            }

            break
        }
        case 'remove_text': {
            const { path, offset, text } = operation
            const [blockIndex] = path
            const endOffset = offset + text.length

            // the start anchor is the start point of the text that's gonna be removed
            const startAnchorPoint = { path, offset }
            // the end anchor is the end point of the text that's gonna be removed
            const endAnchorPoint = { path, offset: endOffset }

            let beforeStartAnchorPoint = Editor.before(editor, startAnchorPoint, {
                unit: 'character',
            })
            // we only want to consider the point if it's in the same block
            if (beforeStartAnchorPoint && beforeStartAnchorPoint.path[0] !== blockIndex) {
                beforeStartAnchorPoint = undefined
            }

            let afterEndAnchorPoint = Editor.after(editor, endAnchorPoint, { unit: 'character' })
            // we only want to consider the point if it's in the same block
            if (afterEndAnchorPoint && afterEndAnchorPoint.path[0] !== blockIndex) {
                afterEndAnchorPoint = undefined
            }

            const isCharBeforeStartAnchorWhitespace = beforeStartAnchorPoint
                ? isWhitespace(
                      Editor.string(editor, {
                          anchor: beforeStartAnchorPoint,
                          focus: startAnchorPoint,
                      }),
                  )
                : true
            const isCharAfterEndAnchorWhitespace = afterEndAnchorPoint
                ? isWhitespace(
                      Editor.string(editor, { anchor: endAnchorPoint, focus: afterEndAnchorPoint }),
                  )
                : true

            const textContents = Editor.string(editor, path)

            const startAnchorWordBounds = getWordBounds(textContents, offset)
            const endAnchorWordBounds = getWordBounds(textContents, endOffset)

            const isTextStartWhitespace = isWhitespace(text[0])
            const isTextEndWhitespace = isWhitespace(text[text.length - 1])

            const isStartOfFirstWord = startAnchorWordBounds?.start === offset
            const isEndOfLastWord = endAnchorWordBounds?.end === endOffset

            const { wordIndex: startWordIndex } = getWordInfo(editor, blockIndex, path, offset)
            const { wordIndex: endWordIndex } = getWordInfo(editor, blockIndex, path, endOffset)

            const startWordLeftHalf = startAnchorWordBounds
                ? textContents.slice(startAnchorWordBounds.start, offset)
                : ''

            const endWordRightHalf = endAnchorWordBounds
                ? textContents.slice(endOffset, endAnchorWordBounds.end)
                : ''

            const removedWords = splitToWords(text)
            let wasFirstWordChanged = false

            // when the start of the removal is in the start or middle of a word
            if (!isTextStartWhitespace && startAnchorWordBounds) {
                if (isStartOfFirstWord) {
                    // if it's the start and spans the whole word, remove it
                    if (startAnchorWordBounds.end <= endOffset) {
                        ops.push({
                            type: 'remove_word',
                            blockIndex,
                            wordIndex: startWordIndex,
                        })
                        removedWords.shift()
                    } else {
                        ops.push({
                            type: 'change_word_content',
                            blockIndex,
                            wordIndex: startWordIndex,
                            newWordContent: startWordLeftHalf + endWordRightHalf,
                        })
                        wasFirstWordChanged = true
                        break
                    }
                } else {
                    ops.push({
                        type: 'change_word_content',
                        blockIndex,
                        wordIndex: startWordIndex,
                        newWordContent:
                            endAnchorWordBounds && endAnchorWordBounds.end >= endOffset
                                ? startWordLeftHalf + endWordRightHalf
                                : startWordLeftHalf,
                    })
                    wasFirstWordChanged = true
                    removedWords.shift()
                }

                if (removedWords.length === 0) {
                    break
                }
            }

            if (!isTextEndWhitespace) {
                removedWords.pop()
            }

            const removedWordsCount = removedWords.length

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            for (const word of removedWords) {
                ops.push({
                    type: 'remove_word',
                    blockIndex,
                    wordIndex:
                        startWordIndex + (wasFirstWordChanged || isTextStartWhitespace ? 1 : 0),
                })
            }

            if (!isTextEndWhitespace && endAnchorWordBounds) {
                if (isEndOfLastWord) {
                    ops.push({
                        type: 'remove_word',
                        blockIndex,
                        wordIndex: endWordIndex - removedWordsCount,
                    })
                } else {
                    ops.push({
                        type: 'change_word_content',
                        blockIndex,
                        wordIndex: endWordIndex - removedWordsCount - 1,
                        newWordContent: endWordRightHalf,
                    })
                }
            }

            if (
                !wasFirstWordChanged &&
                !isCharAfterEndAnchorWhitespace &&
                !isCharBeforeStartAnchorWhitespace
            ) {
                ops.push({
                    type: 'change_word_content',
                    blockIndex,
                    wordIndex: startWordIndex,
                    newWordContent: startWordLeftHalf + endWordRightHalf,
                })
                ops.push({
                    type: 'remove_word',
                    blockIndex,
                    wordIndex: startWordIndex + 1,
                })
            }

            break
        }
        case 'split_node': {
            if (!Block.isBlock(operation.properties)) {
                break
            }

            const { path } = operation
            const [blockIndex] = path

            const blockText = Editor.string(editor, [blockIndex, 0])
            const newBlockText = Editor.string(editor, [blockIndex, 1])

            const blockLastWordBounds = getWordBounds(blockText, blockText.length)
            const newBlockFirstWordBounds = getWordBounds(newBlockText, 0)

            const [lastBlockText, blockLastTextPath] = Editor.last(editor, [blockIndex, 0])
            const { wordIndex } = getWordInfo(
                editor,
                blockIndex,
                blockLastTextPath,
                Node.string(lastBlockText).length,
            )

            // index of the word, where the split happens
            let splitWordIndex = wordIndex

            // if both are not whitespace, the word was split
            if (!!blockLastWordBounds && !!newBlockFirstWordBounds) {
                ops.push({
                    type: 'change_word_content',
                    blockIndex,
                    wordIndex,
                    newWordContent: blockText.slice(
                        blockLastWordBounds.start,
                        blockLastWordBounds.end,
                    ),
                })
                ops.push({
                    type: 'add_word',
                    blockIndex,
                    wordIndex: splitWordIndex + 1,
                    wordContent: newBlockText.slice(
                        newBlockFirstWordBounds.start,
                        newBlockFirstWordBounds.end,
                    ),
                })
            }

            ops.push({ type: 'split_block', blockIndex, wordIndex: splitWordIndex + 1 })

            break
        }
        case 'move_node': {
            const { path, newPath } = operation
            if (path.length !== 2 || newPath.length !== 2) {
                break
            }

            const [rightBlockIndex] = path
            const [leftBlockIndex] = newPath

            const rightTextContent = Editor.string(editor, path)
            const leftTextContent = Editor.string(editor, [leftBlockIndex])

            const leftEndWordBounds = getWordBounds(leftTextContent, leftTextContent.length)
            const rightStartWordBounds = getWordBounds(rightTextContent, 0)

            const [leftLastBlockText, leftLlockLastTextPath] = Editor.last(editor, [leftBlockIndex])
            const { wordIndex } = getWordInfo(
                editor,
                leftBlockIndex,
                leftLlockLastTextPath,
                Node.string(leftLastBlockText).length,
            )

            ops.push({
                type: 'merge_block',
                blockIndex: rightBlockIndex,
            })

            if (!!leftEndWordBounds && !!rightStartWordBounds) {
                ops.push({
                    type: 'change_word_content',
                    blockIndex: leftBlockIndex,
                    wordIndex,
                    newWordContent:
                        leftTextContent.slice(leftEndWordBounds.start, leftEndWordBounds.end) +
                        rightTextContent.slice(
                            rightStartWordBounds.start,
                            rightStartWordBounds.end,
                        ),
                })
                ops.push({
                    type: 'remove_word',
                    blockIndex: leftBlockIndex,
                    wordIndex: wordIndex + 1,
                })
            }

            break
        }
        case 'merge_node': {
            const { path, properties } = operation

            if (!Block.isBlock(properties)) {
                break
            }

            const [rightBlockIndex] = path
            const leftBlockIndex = rightBlockIndex - 1

            const rightTextContent = Editor.string(editor, path)
            const leftTextContent = Editor.string(editor, [leftBlockIndex])

            const leftEndWordBounds = getWordBounds(leftTextContent, leftTextContent.length)
            const rightStartWordBounds = getWordBounds(rightTextContent, 0)

            const [leftLastBlockText, leftLlockLastTextPath] = Editor.last(editor, [leftBlockIndex])
            const { wordIndex } = getWordInfo(
                editor,
                leftBlockIndex,
                leftLlockLastTextPath,
                Node.string(leftLastBlockText).length,
            )

            if (!!leftEndWordBounds && !!rightStartWordBounds) {
                const newWordContent =
                    leftTextContent.slice(leftEndWordBounds.start, leftEndWordBounds.end) +
                    rightTextContent.slice(rightStartWordBounds.start, rightStartWordBounds.end)

                ops.push({
                    type: 'change_word_content',
                    blockIndex: leftBlockIndex,
                    wordIndex,
                    newWordContent,
                })
                ops.push({
                    type: 'remove_word',
                    blockIndex: leftBlockIndex,
                    wordIndex: wordIndex + 1,
                })
            }

            ops.push({
                type: 'merge_block',
                blockIndex: rightBlockIndex,
            })
        }
    }

    return ops
}

/**
 * Calculates a few values for the translation process.
 *
 * * `wordIndex` - index of the word inside the block. If the word is the first word, it's -1. If the operation is outside of any words bounds
 * it's the index of the last word before the opOffset.
 *
 * * `blockTextOffset` - the opOffset translated to the whole block content.
 *
 * * `textNodeOffset` - the offset of the text node of the operation. `blockTextOffset + opOffset === textNodeOffset`
 */
function getWordInfo(editor: Editor, blockIndex: number, opPath: Path, opOffset: number) {
    let wordCount = 0
    let blockTextOffset = 0
    let textNodeOffset = 0

    for (const [textNode, textPath] of Editor.nodes(editor, {
        at: [blockIndex],
        match: Text.isText,
    })) {
        if (Path.equals(textPath, opPath)) {
            const words = countWordsUntilOffset(textNode.text, opOffset)
            wordCount += words
            blockTextOffset += opOffset
            break
        }

        const doesTextEndWithWhitespace =
            typeof textNode.text[textNode.text.length - 1] === 'string'
                ? isWhitespace(textNode.text[textNode.text.length - 1])
                : true

        const words = countWords(textNode.text)

        // if the text node does *not* end with whitespace, we need to subtract one from the word count because
        // it might continue in the next text node.
        wordCount += words - (doesTextEndWithWhitespace ? 0 : 1)

        blockTextOffset += textNode.text.length
        textNodeOffset += textNode.text.length
    }

    return {
        wordIndex: wordCount - 1,
        blockTextOffset,
        textNodeOffset,
    }
}

/**
 * Returns a point, 1 char before the anchor point.
 * Since Editor.before() does not work well with tags, we omit this case and do it manually.
 */
function getBeforeAnchor(editor: Editor, anchorPoint: Point): Point | undefined {
    const [blockIndex] = anchorPoint.path

    // if the before point is inside the same block, use Editor.before()
    if (anchorPoint.offset > 0) {
        return Editor.before(editor, anchorPoint, { unit: 'character' })
    }

    let prevEntry: NodeEntry<ContentText> | null = null
    for (const textEntry of Editor.nodes(editor, { at: [blockIndex], match: Text.isText })) {
        const [, textPath] = textEntry

        if (Path.equals(anchorPoint.path, textPath)) {
            return prevEntry ? Editor.end(editor, prevEntry[1]) : undefined
        }
        prevEntry = textEntry
    }
}

/**
 * Returns a point, 1 char after the anchor point.
 * Since Editor.after() does not work well with tags, we omit this case and do it manually.
 */
function getAfterAnchor(editor: Editor, anchorPoint: Point): Point | undefined {
    const [blockIndex] = anchorPoint.path

    // if the after point is inside the same block, use Editor.after()
    if (Point.isBefore(anchorPoint, Editor.end(editor, anchorPoint.path))) {
        return Editor.after(editor, anchorPoint, { unit: 'character' })
    }

    let nextEntry: NodeEntry<ContentText> | null = null

    for (const textEntry of Editor.nodes(editor, {
        at: [blockIndex],
        match: Text.isText,
        reverse: true,
    })) {
        const [, textPath] = textEntry

        if (Path.equals(anchorPoint.path, textPath)) {
            return nextEntry ? Editor.start(editor, nextEntry[1]) : undefined
        }
        nextEntry = textEntry
    }
}
