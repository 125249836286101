import { Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { useSpeakers, useSpeakerRolesByRole } from 'src/state/SpeakersProvider'
import { useLegalAnnotations } from 'src/state/LegalAnnotationsProvider'
import { useExhibits } from 'src/state/ExhibitsProvider'
import { Block, isExaminationLegalAnnotation } from 'src/components/Editor/plugins/withTranscript'
import { ExaminationLegalAnnotation } from 'src/components/Editor/plugins/withTranscript/Block'
import { getSpeakerHotkey } from 'src/utils/speaker'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'

const propagationObject = {
    preventDefault: true,
    stopPropagation: true,
    stopImmediatePropagation: true,
}

export default function useEditorSpeaker() {
    const editor = useSlateStatic()
    const speakerRolesByRole = useSpeakerRolesByRole()
    const { speakers, setIsSelectSpeakerPopupVisible } = useSpeakers([
        'speakers',
        'setIsSelectSpeakerPopupVisible',
    ])
    const { speakersByLegalAnnotation } = useLegalAnnotations()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const { setIsExhibitPopupVisible } = useExhibits()

    const handleSelectSpeaker = (e: KeyboardEvent) => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return
        setIsSelectSpeakerPopupVisible && setIsSelectSpeakerPopupVisible(true)
    }

    useMousetrap(['mod+shift+s'], handleSelectSpeaker, {
        preventDefault: true,
        stopImmediatePropagation: true,
    })

    const handleSpeakerHotkeys = (e: KeyboardEvent) => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return
        const pressHotkey = e.key.toLowerCase()

        for (const speaker of speakers) {
            const speakerHotkey = getSpeakerHotkey(speaker.role, speakerRolesByRole)

            if (speakerHotkey?.toLowerCase() === pressHotkey) {
                const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
                Block.setSpeaker(editor, blockPath, speaker)
                break
            }
        }
    }

    useMousetrap(['f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8'], handleSpeakerHotkeys, {
        label: 'Speaker Hotkey',
        ...propagationObject,
    })

    const handleQuestionAnnotationHotkey = () => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return

        const [[block, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })

        if (
            isExaminationLegalAnnotation(ExaminationLegalAnnotation.Q) &&
            speakersByLegalAnnotation[ExaminationLegalAnnotation.Q] &&
            block?.speakerId &&
            block?.speakerId !== speakersByLegalAnnotation[ExaminationLegalAnnotation.Q]
        ) {
            Block.setPendingLegalAnnotation(editor, blockPath, ExaminationLegalAnnotation.Q)
        } else {
            Block.setLegalAnnotation(editor, blockPath, ExaminationLegalAnnotation.Q)
            Block.setPendingLegalAnnotation(editor, blockPath, null)
        }
    }

    const handleAnswerAnnotationHotkey = () => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return

        const [[block, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })

        if (
            isExaminationLegalAnnotation(ExaminationLegalAnnotation.A) &&
            speakersByLegalAnnotation[ExaminationLegalAnnotation.A] &&
            block?.speakerId &&
            block?.speakerId !== speakersByLegalAnnotation[ExaminationLegalAnnotation.A]
        ) {
            Block.setPendingLegalAnnotation(editor, blockPath, ExaminationLegalAnnotation.A)
        } else {
            Block.setLegalAnnotation(editor, blockPath, ExaminationLegalAnnotation.A)
            Block.setPendingLegalAnnotation(editor, blockPath, null)
        }
    }

    const handleColloquyAnnotationHotkey = () => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return

        const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
        Block.setLegalAnnotation(editor, blockPath, 'c')
    }

    const handleExhibitHotkey = () => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return
        setIsExhibitPopupVisible && setIsExhibitPopupVisible(true)
    }

    // Note: Annotaions are separated to multiple function calls
    // since the hotkey combos are generating some special characters
    useMousetrap('alt+q', handleQuestionAnnotationHotkey, {
        label: 'Annotate Question Hotkey',
        ...propagationObject,
    })

    useMousetrap('alt+a', handleAnswerAnnotationHotkey, {
        label: 'Annotate Answer Hotkey',
        ...propagationObject,
    })

    useMousetrap('alt+c', handleColloquyAnnotationHotkey, {
        label: 'Annotate Colloquy Hotkey',
        ...propagationObject,
    })

    useMousetrap('alt+x', handleExhibitHotkey, {
        label: 'Add Exhibit Hotkey',
        ...propagationObject,
    })
}
