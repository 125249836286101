import styled, { keyframes } from 'styled-components'
import { palette } from 'styled-tools'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useLiveMachine } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'

const WelcomeSessionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50px;
    width: 100%;
    height: 100%;
`
const SessionWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
`
const textAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px); 
    }
    to {
        opacity: 1;
        transform: translateY(0); 
    }
`
const TextAnimationContainer = styled.div`
    overflow: hidden;
`
const GradientText = styled.span`
    background: linear-gradient(270deg, #2b7abb -2%, #194ddd 44.65%, #573aa6 96.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
    gap: 1px;
    pointer-events: none;
    user-select: none;
    animation: ${textAnimation} 0.5s ease-out;
    ::-webkit-animation: ${textAnimation} 0.5s ease-out;

    @media (max-width: 575.98px) {
        width: calc(50% + 10px);
        font-size: clamp(25px, 5vw, 35px);
        line-height: 35px;
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        width: calc(50% + 10px);
        font-size: clamp(25px, 5vw, 35px);
        line-height: 35px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: calc(75% + 10px);
        font-size: clamp(35px, 5vw, 42px);
        line-height: 47px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        width: calc(85% + 10px);
        font-size: clamp(45px, 5vw, 55px);
        line-height: 55px;
    }

    @media (min-width: 1200px) {
        width: calc(100vw - 450px);
        font-size: clamp(55px, 5vw, 65px);
        line-height: 75px;
    }
`
const ContentText = styled.span`
    width: 580px;
    color: ${palette('grey', 10)};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    pointer-events: none;
    user-select: none;
    display: inline-block;
    animation: ${textAnimation} 1s ease-out;
    ::-webkit-animation: ${textAnimation} 1s ease-out;
`

export const WelcomeSession = () => {
    const [state] = useAppMachine(['workerName'])
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const [
        {
            context: { mediaSource },
        },
    ] = useLiveMachine(['mediaSource'])

    const isEditorWithVc =
        mediaSource && !isRealTimeReadOnly && mediaSource.toLowerCase() === 'verbit_connect'
    const text = isEditorWithVc
        ? "Add speakers and terms to the right panel for seamless reporting. Once you're ready, click the record button at the top to begin recording."
        : 'The live transcript of the deposition will appear here as soon as it commences. Thank you for your patience.'
    return (
        <WelcomeSessionContainer>
            <SessionWrapper>
                <GradientText>Welcome</GradientText>
                <GradientText>{state.context.workerName}</GradientText>

                <TextAnimationContainer>
                    <ContentText>{text}</ContentText>
                </TextAnimationContainer>
            </SessionWrapper>
        </WelcomeSessionContainer>
    )
}
