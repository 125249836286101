import React from 'react'

import styled, { createGlobalStyle } from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { HashRouter, Switch, Route } from 'react-router-dom'

import { SessionStatusProvider } from 'src/state/SessionStatusProvider'
import { ModalProvider } from 'src/state/ModalProvider'
import { AnalyticsProvider } from 'src/analytics/ContextProvider'
import { ToastProvider } from 'src/components/Toasts/ToastContext'
import { SessionProviderGate } from 'src/components/Session/SessionProviderGate'
import { App, TopBar } from 'src/components/App'
import { UnsupportedBrowser } from 'src/components/UnsupportedBrowser'
import { useIsTaskMachineActive } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { SpellcheckerProvider } from 'src/components/Spellchecker/Spellchecker'
import { HlsDebugTool } from 'src/components/HlsDebugTool/HlsDebugTool'
import { FeatureFlagProvider } from 'src/state/FeatureFlagProvider'

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    min-width: 700px;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  body {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  #root {
    width: 100%;
    height: 100vh;
  }

  ::selection {
    background: ${palette('cloudBlueLight', 9)} !important;
  }

  .bp4-overlay,
  .bp4-overlay-content,
  .bp4-transition-container {
    z-index: ${theme('zIndexes.headerPopovers')};
  }

  .bp4-popover.trax-select-popover,
  .bp4-popover.trax-popover {
    background-color: ${palette('white', 0)};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px ${palette('grey', 13)};
    overflow: hidden;
  }

  .bp4-popover.trax-select-popover {
    .bp4-input-group {
      margin: 5px 7px;
      height: 28px;
      background-color: ${palette('white', 0)};
      border-radius: 4px;
      border: solid 1px ${palette('grey', 13)};

      .bp4-icon {
        margin: 5px;
      }

      .bp4-input {
        height: 100%;
        box-shadow: none !important;
      }

      .bp4-button.bp4-minimal {
        min-width: auto;
        min-height: auto;
        margin: 0;
        padding: 0;
      }
    }
  }

  .toaster-position {
    z-index: ${theme('zIndexes.toasts')};
    top: 60px !important;
  }
`
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    flex: 1;
    flex-direction: column;
    background-color: ${palette('cloudBlueLight', 7)};
`

export function Router() {
    const [state] = useAppMachine(['workerName'])
    const isTaskMachineActive = useIsTaskMachineActive()

    return (
        <>
            <GlobalStyle />
            <ToastProvider>
                <AnalyticsProvider>
                    <SessionStatusProvider>
                        <SpellcheckerProvider>
                            <SessionProviderGate>
                                <FeatureFlagProvider>
                                    <ModalProvider>
                                        <Container>
                                            <TopBar
                                                workerName={state.context.workerName}
                                                isUserWorking={isTaskMachineActive}
                                            />
                                            <HashRouter hashType="noslash">
                                                <Switch>
                                                    <Route
                                                        path="/hls-debug"
                                                        exact
                                                        component={HlsDebugTool}
                                                    />
                                                    <Route path="/" component={App} />
                                                </Switch>
                                            </HashRouter>
                                        </Container>
                                    </ModalProvider>
                                </FeatureFlagProvider>
                            </SessionProviderGate>
                        </SpellcheckerProvider>
                    </SessionStatusProvider>
                </AnalyticsProvider>
            </ToastProvider>
        </>
    )
}
