import { EventEmitter } from 'events'
import { MockStreamPlayer } from './MockStreamPlayer'
import { MockDelayRange } from './utils'

export enum MockResourceType {
    Static = 'static',
    Stream = 'stream',
}

export enum MockManagerMode {
    Replay = 'replay',
    Record = 'record',
    Inactive = 'inactive',
}

type ResourceData =
    | {
          type: MockResourceType.Static
          data: any
      }
    | { type: MockResourceType.Stream; data: any[] }

type MockManagerEvents = {
    stream_player: () => void
}

type MockManagerReplayOptions = {
    mode: MockManagerMode.Replay
} & ({ path: string } | { data: Record<string, ResourceData> }) & { streamOffset?: number }

type MockManagerRecordOptions = {
    mode: MockManagerMode.Record
    initialData?: Record<string, ResourceData>
    trapErrors?: boolean
}

type MockManagerInactiveOptions = {
    mode: MockManagerMode.Inactive
}

export type MockManagerOptions =
    | MockManagerInactiveOptions
    | MockManagerRecordOptions
    | MockManagerReplayOptions

type MockManagerReplayStreamOptions = {
    delay?: MockDelayRange
}

export class MockManager {
    private mode: MockManagerMode
    private _isActive: boolean = true
    private resources: Record<string, ResourceData> = {}
    private _streamPlayers: Record<string, MockStreamPlayer> = {}
    private _events = new EventEmitter<MockManagerEvents>()

    private readyPromise: Promise<boolean> = Promise.resolve(false)

    constructor(options: MockManagerOptions) {
        console.log(`[MockManager] Initializing with options ${JSON.stringify(options)}`)
        this.mode = options.mode

        switch (options.mode) {
            case MockManagerMode.Replay:
                if ('path' in options) {
                    this.readyPromise = this.loadMockData(options.path).then(() => true)
                } else {
                    this.resources = options.data
                    this.readyPromise = Promise.resolve(true)
                }

                if (options.streamOffset) {
                    this.replayToOffset(options.streamOffset)
                }

                break
            case MockManagerMode.Record:
                this.resources = options.initialData || {}
                this.readyPromise = Promise.resolve(true)

                if (options.trapErrors) {
                    MockManager.createErrorTrap(this)
                }

                break
            case MockManagerMode.Inactive:
                this._isActive = false
                this.readyPromise = Promise.resolve(false)
                break
        }
    }

    isReady() {
        return this.readyPromise
    }

    isActive() {
        return this._isActive
    }

    getMode() {
        return this.mode
    }

    private async loadMockData(path: string) {
        console.log(`[MockManager] Loading mock data from ${path}`)

        try {
            const data = await (await fetch(path)).json()
            this.resources = data.resources
            console.log(`[MockManager] Loaded mock data`, data)
        } catch (e) {
            throw new Error(`[MOCK] Could not load replay file from "${path}".`)
        }
    }

    record(type: MockResourceType, resourceId: string, data: any) {
        if (this.mode !== MockManagerMode.Record) {
            console.warn(`[MockManager] Recording is disabled in replay mode`)
            return
        }

        switch (type) {
            case MockResourceType.Static:
                this.resources[resourceId] = { type, data }
                break
            case MockResourceType.Stream:
                if (!this.resources[resourceId]) {
                    this.resources[resourceId] = { type, data: [] }
                }
                this.resources[resourceId].data.push(data)
                break
        }
    }

    getMockData() {
        return { resources: { ...this.resources } }
    }

    downloadMock() {
        const json = JSON.stringify(this.getMockData())
        const element = document.createElement('a')
        element.setAttribute(
            'href',
            `data:application/json;charset=utf-8,${encodeURIComponent(json)}`,
        )
        element.setAttribute('download', 'mock.json')
        element.style.display = 'none'
        document.body.append(element)

        element.click()
        document.body.removeChild(element)
    }

    replayStatic(resourceId: string) {
        if (this.mode !== MockManagerMode.Replay) {
            console.warn(`[MockManager] Replay is disabled in record mode`)
            return
        }

        const resource = this.resources[resourceId]

        if (resource?.type !== MockResourceType.Static) {
            throw new Error(`[MOCK] Resource "${resourceId}" is not a static resource`)
        }

        return resource.data
    }

    replayStream(resourceId: string, options?: MockManagerReplayStreamOptions) {
        if (this.mode !== MockManagerMode.Replay) {
            console.warn(`[MockManager] Replay is disabled in record mode`)
            return
        }

        if (this.resources[resourceId]?.type !== MockResourceType.Stream) {
            throw new Error(`[MOCK] Resource "${resourceId}" is not a stream resource`)
        }

        if (!this._streamPlayers[resourceId]) {
            this._streamPlayers[resourceId] = new MockStreamPlayer({
                id: resourceId,
                events: this.resources[resourceId].data,
                delay: options?.delay,
            })
            this._events.emit('stream_player')
        }

        return this._streamPlayers[resourceId]
    }

    async replayToOffset(offset: number) {
        await this.isReady()

        // wait for 2 seconds for everything to be settled
        await new Promise((r) => setTimeout(r, 5_000))

        console.log(`[MockManager] Replaying to offset ${offset}`)

        for (const player of this.getAllStreamPlayers()) {
            player.step(offset)
        }
    }

    getAllStreamPlayers() {
        return Object.values(this._streamPlayers)
    }

    on<K extends keyof MockManagerEvents>(event: K, listener: MockManagerEvents[K]) {
        this._events.on(event, listener)
    }

    off<K extends keyof MockManagerEvents>(event: K, listener: MockManagerEvents[K]) {
        this._events.off(event, listener)
    }

    static createErrorTrap(manager: MockManager) {
        window.addEventListener('error', (error) => {
            console.error(error)

            if (manager.isActive()) {
                console.debug(`[MOCK] mock data dump:`, manager.getMockData())
            }

            window.Rollbar?.error(error)
        })

        window.addEventListener('unhandledrejection', (error) => {
            console.error(error)

            if (manager.isActive()) {
                console.debug(`[MOCK] mock data dump:`, manager.getMockData())
            }

            window.Rollbar?.error(error)
        })
    }
}
