import { useEffect, useReducer, useState } from 'react'

import { Icon } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { ifProp } from 'styled-tools'

import { MockManager } from '../MockManager'
import { LiveState, MockStreamPlayer } from '../MockStreamPlayer'

const IconWrapper = styled.button<{ disabled?: boolean }>`
    cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
    padding: 0px 4px;
    margin: 0px 4px;

    background: inherit;
    border: 0;

    opacity: ${ifProp('disabled', '0.5', '1')};
`

type MockReplayControllerProps = {
    manager: MockManager
}

export function MockReplayController({ manager }: MockReplayControllerProps) {
    const [, triggerRenderUpdate] = useReducer((x) => x + 1, 0)

    useEffect(() => {
        manager.on('stream_player', triggerRenderUpdate)

        return () => {
            manager.off('stream_player', triggerRenderUpdate)
        }
    })

    const players = manager.getAllStreamPlayers()

    return (
        <>
            {players.map((player) => (
                <StreamPlayerController key={player.getId()} player={player} />
            ))}
            {players.length === 0 && <span>No stream players</span>}
        </>
    )
}

type StreamPlayerControllerProps = {
    player: MockStreamPlayer
}

function StreamPlayerController({ player }: StreamPlayerControllerProps) {
    const [isPlaying, setIsPlaying] = useState(false)
    const [liveState, setLiveState] = useState<LiveState>(player.getLiveState())

    const play = () => {
        player.play()
        setIsPlaying(true)
    }

    const pause = () => {
        player.pause()
        setIsPlaying(false)
    }

    const step = (count?: number) => {
        player.step(count)
    }

    const onPlayPauseToggle = (e: React.SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (isPlaying) {
            pause()
        } else {
            play()
        }
    }

    const onStepForward = (e: React.SyntheticEvent, count?: number) => {
        e.preventDefault()
        e.stopPropagation()

        if (isPlaying) return
        step(count)
    }

    const onStep10StepsForward = (e: React.SyntheticEvent) => onStepForward(e, 100)

    useEffect(() => {
        player.on('live_state_update', setLiveState)

        return () => {
            player.off('live_state_update', setLiveState)
        }
    }, [player])

    return (
        <>
            <span>{player.getId()}</span>
            <IconWrapper
                onClick={onPlayPauseToggle}
                onTouchStart={onPlayPauseToggle}
                disabled={liveState.index + 1 >= liveState.length}
            >
                <Icon icon={isPlaying ? 'pause' : 'play'} />
            </IconWrapper>
            <IconWrapper
                onClick={onStepForward}
                onTouchStart={onStepForward}
                disabled={isPlaying || liveState.index + 1 >= liveState.length}
            >
                <Icon icon="step-forward" />
            </IconWrapper>
            <IconWrapper
                onClick={onStep10StepsForward}
                onTouchStart={onStep10StepsForward}
                disabled={isPlaying || liveState.index + 10 >= liveState.length}
            >
                <Icon icon="step-forward" />
                <Icon icon="step-forward" />
            </IconWrapper>
            <span>
                {liveState.index} / {liveState.length}
            </span>
        </>
    )
}
