import { createContext, useState, useContext, ReactNode, useMemo } from 'react'
import { EventsList } from 'src/models/events'

interface EventsMarkingContextvalue {
    eventsList: EventsList[]
    setEventsList: React.Dispatch<React.SetStateAction<EventsList[]>>
    targetIndex?: number
    setTargetIndex?: React.Dispatch<React.SetStateAction<number | undefined>>
    searchValue?: string
    setSearchValue?: React.Dispatch<React.SetStateAction<string>>
}
const EventsMarkingContext = createContext<EventsMarkingContextvalue | undefined>(undefined)

interface EventsMarkingProviderProps {
    children: ReactNode
}

const EventsMarkingProvider = ({ children }: EventsMarkingProviderProps) => {
    const [eventsList, setEventsList] = useState<EventsList[]>([])
    const [targetIndex, setTargetIndex] = useState<number | undefined>()
    const [searchValue, setSearchValue] = useState<string>('')

    return (
        <EventsMarkingContext.Provider
            value={useMemo(
                () => ({
                    eventsList,
                    setEventsList,
                    targetIndex,
                    setTargetIndex,
                    searchValue,
                    setSearchValue,
                }),
                [
                    eventsList,
                    setEventsList,
                    targetIndex,
                    setTargetIndex,
                    searchValue,
                    setSearchValue,
                ],
            )}
        >
            {children}
        </EventsMarkingContext.Provider>
    )
}

const useEventsMarking = () => {
    const exhibitsContextValue = useContext(EventsMarkingContext)
    if (!exhibitsContextValue)
        throw new Error('useEventsMarking must be used within EventsMarkingProvider.')
    return exhibitsContextValue
}

export { EventsMarkingProvider, useEventsMarking }
