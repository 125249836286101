import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Icon, Menu, MenuItem, Position, Spinner, Toaster } from '@blueprintjs/core'
import { ItemListRenderer, ItemPredicate, ItemRenderer } from '@blueprintjs/select'
import { Button } from '@verbit-ai/verbit-ui-library'
import { CaretDownIcon } from '@verbit-ai/icons-library'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'
import styled, { keyframes, useTheme } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'
import { isEqual, groupBy, pick, omit, isEmpty } from 'lodash/fp'

import { APIError } from 'src/network'
import { Speaker, SpeakerRole } from 'src/models'
import { usePrevious } from 'src/hooks/usePrevious'
import { getDefaultVoiceSampleId, isVoiceSamplePlaying } from 'src/utils/speaker'
import { useGlossary } from 'src/state/GlossaryProvider'
import {
    DEFAULT_SPEAKER,
    PendingSpeaker,
    useSpeakers,
    useSpeakersById,
} from 'src/state/SpeakersProvider'
import {
    useSpeakerVoiceSamplePlayer,
    useToggleSpeakerVoiceSample,
} from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { PersonIcon } from 'src/components/icons'
import { SelectInput } from 'src/components/Session/panels/SpeakerPanel/SelectInput'
import { AutoSuggestInput } from 'src/components/Session/panels/SpeakerPanel/AutoSuggestInput'

import { TickIcon, Header } from '../common'
import { FormField } from './FormField'
import { VoiceSampleField } from './VoiceSampleField'

const slideInLeft = keyframes`
    from {
        transform: translateX(100%);
        
    }
    to {
        transform: translateX(0);
    }
`

const slideOutRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`

const Container = styled.div<{ isClosing: boolean }>`
    position: absolute;
    top: 0;
    right: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 22vw;
    min-width: 290px;
    max-width: 422px;
    padding: 0 16px;
    background-color: ${palette('white', 0)};
    animation: ${ifProp('isClosing', slideOutRight, slideInLeft)} 500ms
        cubic-bezier(0.25, 1, 0.5, 1) forwards;
`

const FormFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
`

const FormHeader = styled(Header)`
    margin-bottom: -5px;
`

const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    gap: 8px;
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 32px;
`

const StyledSelectInput = styled(SelectInput)`
    .bp4-popover-target {
        width: 100%;
        .bp4-input-group {
            input.bp4-input {
                height: auto;
                font-size: 14px;
                line-height: 25px;
                border: solid 1px ${palette('grey', 8)};
            }
        }
    }
`

const StyledMenu = styled(Menu)`
    overflow-y: auto;
    max-height: 180px;
    padding: 0;
    border-radius: 4px;
    border: solid 1px ${palette('cloudBlueLight', 0)};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
`

const StyledMenuItem = styled(MenuItem)`
    align-content: center;
    padding: 10px 8px;
    border-radius: 0;
    font-size: 14px;
    line-height: 16px;
    color: ${palette('navy', 6)};

    .bp4-text-overflow-ellipsis {
        display: flex;
        align-items: center;
    }

    .bp4-icon,
    > svg {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
        color: ${palette('grey', 8)};
    }

    &:hover,
    &.bp4-active {
        background-color: ${palette('cloudBlueLight', 2)} !important;
        color: ${palette('navy', 6)} !important;

        .bp4-icon,
        > svg {
            color: ${palette('blue', 0)};
        }
    }
`

const StyledMenuHeader = styled(StyledMenuItem)`
    pointer-events: none;
    font-weight: 600;
    color: ${palette('greyBlue', 1)};
    border-bottom: 1px solid ${palette('cloudBlueLight', 0)};
`

const RoleColorLabel = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 2px;
    background-color: ${prop('color')};
`

const Role = styled.div`
    flex: 1;
`

const VoiceSamplesFormField = styled(FormField)`
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 32px;
`

const LabelFormField = styled(FormField)`
    font-size: 14px;
    font-weight: 400;
    color: ${palette('grey', 10)};
    margin-bottom: 8px;
`

const StyledFormField = styled(FormField)`
    margin-bottom: 16px;
`

const VoiceSampleList = styled.div`
    flex: 1;
    overflow-y: auto;
`

const SubmitNetworkError = styled.div`
    margin-bottom: 25px;
    text-align: center;
    font-size: 14px;
    color: ${palette('red', 0)};
`

const SubmitSpinner = styled(Spinner)`
    margin-right: 6px;

    svg {
        width: 16px;
        height: 16px;
    }
`

export const AnchorButton = styled.button`
    padding: 0 19px;
    font-size: 14px;
    color: ${palette('navy', 2)};
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 250ms ease-out;
    background: transparent;
    border: none;

    &:hover {
        opacity: 0.75;
    }
`

const StyledAutoSuggestInput = styled(AutoSuggestInput)`
    .bp4-popover-target {
        width: 100%;
        .bp4-input-group {
            input.bp4-input {
                height: auto;
                font-size: 14px;
                line-height: 25px;
                border: solid 1px ${palette('grey', 8)};
            }
        }
    }
`

const filterStringValue: ItemPredicate<string> = (query, value, i, exactMatch) => {
    const normalizedValue = value.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    return exactMatch
        ? normalizedValue === normalizedQuery
        : normalizedValue.indexOf(normalizedQuery) >= 0
}

const filterSpeakerRole: ItemPredicate<SpeakerRole> = (query, { role }, i, exactMatch) =>
    filterStringValue(query, role, i, exactMatch)

const roleListRenderer: ItemListRenderer<SpeakerRole> = ({
    itemsParentRef,
    renderItem,
    filteredItems,
}) => (
    <StyledMenu ulRef={itemsParentRef}>
        {filteredItems.length ? (
            filteredItems.map(renderItem)
        ) : (
            <StyledMenuItem text="No Results" disabled />
        )}
    </StyledMenu>
)

const nameListRenderer: ItemListRenderer<string> = ({
    itemsParentRef,
    renderItem,
    filteredItems,
}) => (
    <StyledMenu ulRef={itemsParentRef}>
        <StyledMenuHeader text="People from Glossary" />
        {filteredItems.length ? (
            filteredItems.map(renderItem)
        ) : (
            <StyledMenuItem text="No Suggestions" disabled />
        )}
    </StyledMenu>
)

const nameRenderer: ItemRenderer<string> = (name, { handleClick, modifiers }) => (
    <StyledMenuItem
        key={name}
        text={name}
        onClick={handleClick}
        active={modifiers.active}
        icon={<PersonIcon />}
    />
)

const StyledToaster = styled(Toaster)`
    .bp4-toast {
        width: 100%;
        min-width: unset;
    }
`

type FormErrors<T> = {
    [Key in keyof T]?: string
}

export const SpeakerForm = () => {
    const theme = useTheme()
    const { currentPlayingVoiceSample, ...speakerVoiceSamplePlayer } = useSpeakerVoiceSamplePlayer()
    const toggleSpeakerVoiceSample = useToggleSpeakerVoiceSample()
    const {
        speakers,
        addSpeaker,
        updateSpeaker,
        speakerRoles,
        speakerForm,
        setSpeakerFormValue,
        setIsSpeakerFormVisible,
        defaultRole,
    } = useSpeakers()
    const speakersById = useSpeakersById()
    const { glossary } = useGlossary()

    const containerRef = useRef<HTMLDivElement>(null)
    const toasterRef = useRef<Toaster>(null)

    const [errors, setErrors] = useState<FormErrors<Speaker>>({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitNetworkError, setSubmitNetworkError] = useState('')
    const [isClosing, setIsClosing] = useState(false)

    const { value: formValue } = speakerForm
    const defaultSampleId = useMemo(
        () => getDefaultVoiceSampleId(formValue.samples),
        [formValue.samples],
    )
    const selectedSpeakerRole = useMemo(() => {
        return speakerRoles.find(({ role }) => role === formValue.role)
    }, [formValue, speakerRoles])
    const sourceSpeaker = useMemo(
        () => (formValue.id ? speakersById[formValue.id] : null),
        [speakersById, formValue.id],
    )
    const nameSuggestions = useMemo(
        () => glossary.terms.filter((term) => term.category === 'person').map(({ text }) => text),
        [glossary],
    )
    const occupiedSpeakerRoles = useMemo(() => groupBy('role', speakers), [speakers])
    const prevSourceSpeaker = usePrevious(sourceSpeaker)

    const close = useCallback(() => setIsClosing(true), [])
    const onContainerAnimationEnd = useCallback(() => {
        if (isClosing) {
            setIsSpeakerFormVisible(false)
            setSpeakerFormValue(DEFAULT_SPEAKER)
            setErrors({})
            setSubmitNetworkError('')
            setIsClosing(false)
        }
    }, [isClosing, setIsSpeakerFormVisible, setSpeakerFormValue])

    const onFieldChange = useCallback(
        function <T extends keyof Speaker>(fieldName: T) {
            return (fieldValue: Speaker[T]) => {
                setErrors((errors) => ({ ...errors, [fieldName]: '' }))
                setSpeakerFormValue((speaker) => ({ ...speaker, [fieldName]: fieldValue }))
            }
        },
        [setSpeakerFormValue],
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onNameChange = useCallback(onFieldChange('name'), [onFieldChange])
    const onRoleChange = useCallback(
        ({ role }: SpeakerRole) => {
            setErrors({ ...errors, name: '' })
            onFieldChange('role')(role)
        },
        [onFieldChange, errors],
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onVoiceSamplesChange = useCallback(onFieldChange('samples'), [onFieldChange])

    const onSubmit = useCallback(async () => {
        setSubmitNetworkError('')

        const nextFormErrors: FormErrors<Speaker> = {}
        const speakerFormValue = { ...formValue }

        if (!speakerFormValue.name.trim()) {
            if (selectedSpeakerRole?.requiresName) {
                nextFormErrors.name = 'Speaker name is required'
            } else {
                speakerFormValue.name = speakerFormValue.role
            }
        }

        if (!speakerFormValue.role) {
            nextFormErrors.role = 'Speaker role is required'
        }

        if (Object.values(nextFormErrors).some(Boolean)) {
            setErrors(nextFormErrors)
            return
        }

        setIsSubmitting(true)
        try {
            await (speakerFormValue.id
                ? updateSpeaker({
                      ...speakerFormValue,
                      id: speakerFormValue.id,
                  })
                : addSpeaker(speakerFormValue))
            setIsSubmitting(false)
            close()
        } catch (e) {
            setIsSubmitting(false)
            setSubmitNetworkError(
                e instanceof APIError ? e.message : 'Submit network error, please try again',
            )
        }
    }, [formValue, selectedSpeakerRole, addSpeaker, updateSpeaker, close])

    const onToggleVoiceSample = useCallback(
        (sampleId: string, isPlaying: boolean, e: React.MouseEvent) => {
            if (formValue.id) {
                toggleSpeakerVoiceSample(formValue.id, sampleId, isPlaying, e)
            }
        },
        [toggleSpeakerVoiceSample, formValue.id],
    )
    const onSetVoiceSampleAsDefault = useCallback(
        (sampleId: string) => {
            if (defaultSampleId === null) {
                return
            }

            const updatedSamples = {
                ...formValue.samples,
                [sampleId]: { ...formValue.samples[sampleId], isDefault: true },
                [defaultSampleId]: { ...formValue.samples[defaultSampleId], isDefault: false },
            }

            onVoiceSamplesChange(updatedSamples)
        },
        [formValue.samples, defaultSampleId, onVoiceSamplesChange],
    )
    const onDeleteVoiceSample = useCallback(
        (sampleId: string) => {
            speakerVoiceSamplePlayer.pause()
            onVoiceSamplesChange(omit([sampleId], formValue.samples))
        },
        [formValue.samples, onVoiceSamplesChange, speakerVoiceSamplePlayer],
    )

    const roleRenderer = useCallback<ItemRenderer<SpeakerRole>>(
        ({ role, color }, { handleClick, modifiers }) => (
            <StyledMenuItem
                key={role}
                text={
                    <>
                        <RoleColorLabel color={color} />
                        <Role>{role}</Role>
                        {occupiedSpeakerRoles[role] && <TickIcon />}
                    </>
                }
                onClick={handleClick}
                active={modifiers.active}
            />
        ),
        [occupiedSpeakerRoles],
    )

    const prevIsFormVisible = usePrevious(speakerForm.isVisible)
    useEffect(() => {
        // if we open the form while another speaker's voice sample is playing
        if (
            !prevIsFormVisible &&
            speakerForm.isVisible &&
            currentPlayingVoiceSample?.speakerId !== formValue.id
        ) {
            speakerVoiceSamplePlayer.pause()
        }
        //  if we closed the form while a non-default voice sample of the speaker was still playing
        else if (
            prevIsFormVisible &&
            !speakerForm.isVisible &&
            prevSourceSpeaker?.samples &&
            currentPlayingVoiceSample?.sampleId !==
                getDefaultVoiceSampleId(prevSourceSpeaker?.samples)
        ) {
            speakerVoiceSamplePlayer.pause()
        }
    }, [
        formValue.id,
        prevSourceSpeaker,
        prevIsFormVisible,
        speakerForm.isVisible,
        speakerVoiceSamplePlayer,
        currentPlayingVoiceSample,
    ])

    useEffect(() => {
        if (speakerForm.isVisible) {
            setTimeout(() => speakerForm.nameInputRef.current?.focus({ preventScroll: true }))
        }
    }, [speakerForm.isVisible, speakerForm.nameInputRef])

    // if the source speaker got updated, reload the form and notify the user about it
    useEffect(() => {
        const pickFields = pick<PendingSpeaker, keyof PendingSpeaker>(['name', 'role', 'samples'])

        if (
            sourceSpeaker &&
            prevSourceSpeaker &&
            !isEqual(pickFields(sourceSpeaker), pickFields(prevSourceSpeaker)) &&
            !isEqual(pickFields(sourceSpeaker), pickFields(formValue))
        ) {
            if (
                document.activeElement instanceof HTMLElement &&
                containerRef.current?.contains(document.activeElement)
            ) {
                document.activeElement.blur()
            }

            toasterRef.current?.show({
                intent: 'none',
                message: 'Speaker was updated by one of your teammates',
            })
            setSpeakerFormValue(sourceSpeaker)
        }
    }, [sourceSpeaker, prevSourceSpeaker, formValue, setSpeakerFormValue])

    // Setting the default role if exists
    useEffect(() => {
        if (speakerForm.value.role) {
            return
        }

        if (defaultRole) {
            onFieldChange('role')(defaultRole?.role)
        } else if (speakerRoles.length === 1) {
            onFieldChange('role')(speakerRoles[0].role)
        }
    }, [defaultRole, onFieldChange, speakerForm.value.role, speakerRoles])

    useWillUnmount(() => {
        setSpeakerFormValue(DEFAULT_SPEAKER)
        setIsSpeakerFormVisible(false)
    })

    if (!speakerForm.isVisible) {
        return null
    }

    return (
        <Container
            ref={containerRef}
            isClosing={isClosing}
            onAnimationEnd={onContainerAnimationEnd}
        >
            <FormFieldsContainer>
                <FormHeader>{formValue.id ? 'Edit Speaker' : 'Add Speaker'}</FormHeader>

                {isEmpty(formValue.samples) && !formValue.id && (
                    <LabelFormField label="">
                        Voice sample will be added once the speaker is assigned to audio segments
                    </LabelFormField>
                )}

                <StyledFormField data-testid="speaker-name-field" label="Name" error={errors.name}>
                    <StyledAutoSuggestInput
                        items={nameSuggestions}
                        value={formValue.name}
                        onChange={onNameChange}
                        itemPredicate={filterStringValue}
                        itemListRenderer={nameListRenderer}
                        itemRenderer={nameRenderer}
                        placeholder="Type a Name"
                        inputProps={{ inputRef: speakerForm.nameInputRef }}
                        openOnKeyDown
                    />
                </StyledFormField>

                <StyledFormField data-testid="speaker-role-field" label="Role" error={errors.role}>
                    <StyledSelectInput
                        items={speakerRoles as SpeakerRole[]}
                        selectedItem={selectedSpeakerRole}
                        onItemSelect={(item: unknown) => onRoleChange(item as SpeakerRole)}
                        defaultSelectedItem={defaultRole}
                        itemPredicate={filterSpeakerRole as any}
                        itemListRenderer={roleListRenderer as any}
                        itemRenderer={roleRenderer as any}
                        inputValueRenderer={(item: unknown) => (item as { role: any }).role}
                        placeholder="Select Role"
                        leftElement={
                            selectedSpeakerRole && (
                                <RoleColorLabel color={selectedSpeakerRole?.color} />
                            )
                        }
                        rightElement={
                            <Icon icon={<CaretDownIcon color={theme.palette.grey[8]} />} />
                        }
                        resetOnClose
                    />
                </StyledFormField>

                {!isEmpty(formValue.samples) && (
                    <VoiceSamplesFormField label="Voice Samples">
                        {!isEmpty(formValue.samples) && (
                            <VoiceSampleList>
                                {Object.values(formValue.samples).map((sample) => {
                                    const isPlaying =
                                        !!formValue.id &&
                                        isVoiceSamplePlaying(
                                            formValue.id,
                                            sample.id,
                                            currentPlayingVoiceSample,
                                        )

                                    return (
                                        <VoiceSampleField
                                            key={sample.id}
                                            sample={sample}
                                            isDefault={defaultSampleId === sample.id}
                                            isPlaying={isPlaying}
                                            isBuffering={
                                                isPlaying && speakerVoiceSamplePlayer.isBuffering
                                            }
                                            onToggleVoiceSample={onToggleVoiceSample}
                                            onSetAsDefault={onSetVoiceSampleAsDefault}
                                            onDelete={onDeleteVoiceSample}
                                        />
                                    )
                                })}
                            </VoiceSampleList>
                        )}
                    </VoiceSamplesFormField>
                )}

                <FormFooter>
                    {speakers.length > 0 && (
                        <Button variant="secondary" onClick={close} isDisabled={!speakers.length}>
                            Back
                        </Button>
                    )}

                    <Button
                        variant="primary"
                        onClick={onSubmit}
                        disabled={Object.values(errors).some(Boolean)}
                    >
                        {isSubmitting && <SubmitSpinner />}
                        {formValue.id ? 'Save Changes' : 'Add Speaker'}
                    </Button>
                </FormFooter>
            </FormFieldsContainer>

            <SubmitNetworkError>{submitNetworkError}</SubmitNetworkError>

            <StyledToaster ref={toasterRef} position={Position.TOP} usePortal={false} />
        </Container>
    )
}
